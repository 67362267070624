import { MEDIAS_FEED_PAGE_KEY } from 'src/pages/pagesKeys';

import MediasPage from './MediasPage';
import reducer from './mediasPageReducer';

export default {
  key: MEDIAS_FEED_PAGE_KEY,
  path: '/medias-feed',
  component: MediasPage,
  elId: 'medias-feed-page',
  getReduxReducer: (state, action) => reducer(state[MEDIAS_FEED_PAGE_KEY], action),
};

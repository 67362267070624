import React from 'react';

import CTAButton from 'src/components/cta-button/CTAButton';

import GenericIcon from 'src/components/generic-icon/GenericIcon';

export default function ({ labels, actions, goToSimple }) {
  return (
    <>
      <CTAButton
        id="sfs-go-to-simple-btn"
        action={goToSimple}
        className="btn-as-text onResult"
        label=""
      >
        <GenericIcon layout="i" className="chevron-left-icon" />
        <span>{labels.searchTaiga.goToSimpleSearch}</span>
      </CTAButton>
      <CTAButton
        id="sfs-edit-btn"
        label={labels.searchTaiga.editSearch}
        action={actions.taigaSearchClear}
      />
    </>
  );
}

import { DATA_TYPE_EVENT_CATEGORIES, DATA_TYPE_EXHIBITOR_CATEGORIES, DATA_TYPE_EXHIBITORS, DATA_TYPE_EVENTS, DATA_TYPE_PARTICIPANTS, DATA_TYPE_SPEAKERS, DATA_TYPE_SERVICES, DATA_TYPE_SERVICE_CATEGORIES, DATA_TYPE_PAPERS, DATA_TYPE_PAPER_CATEGORIES, DATA_TYPE_NEWPRODUCTS, DATA_TYPE_GOLDENSUMMITS, DATA_TYPE_GOLDENSUMMIT_CATEGORIES, DATA_TYPE_CARREFOURGENETIQUES, DATA_TYPE_CARREFOURGENETIQUE_CATEGORIES, DATA_TYPE_NEWPRODUCT_CATEGORIES, DATA_TYPE_JOBOFFER_CATEGORIES, DATA_TYPE_JOBOFFERS, DATA_TYPE_CONTESTS, DATA_TYPE_VIDEO_CATEGORIES, DATA_TYPE_USERPROJECTS } from 'app-customs/config/dataConfig';
import { createItem, createItemLang, createItemRoute, createItemToggleLocation, createItemTogglePMR } from 'src/core/util/ConfigItemFactory';
import * as Db from 'src/core/data-and-assets/Db';
import { startUpdate } from 'src/core/data-and-assets/Updater';
import { getUrl } from 'src/core/data-and-assets/DataAssetsUtil';
import { isIOS, isAndroid, isMobile } from 'src/core/util/browser';
import { getCurrent as getLang } from 'src/core/Lang';
import { HOME_PAGE_KEY, FAVORITES_PAGE_KEY, INBOX_PAGE_KEY, KLIPSO_LEADS_PAGE_KEY, LIST_PAGE_KEY, MOBIGEO_PAGE_KEY, GOOGLE_MAP_PAGE_KEY, SEARCH_PAGE_KEY, MEDIAS_PAGE_KEY, CHOOSE_PROFILE_PAGE_KEY, LIST_GROUPS_PAGE_KEY, NOTES_PAGE_KEY, USER_DATA_PAGE_KEY, SEARCH_TAIGA_PAGE_KEY } from 'src/pages/pagesKeys';
import { openUrl } from 'src/core/util/JsTools';
import * as Query from 'src/core/query/Query';
import config from './config';
const {
  DEFAULT_PROFILE,
  WEBAPP_EXH_PROFILE,
  WEBAPP_EVT_PROFILE,
  VISITOR_PROFILE,
  EXHIBITOR_PROFILE,
  WEBAPP_SPK_PROFILE,
  WEBAPP_MAP
} = require('./profiles');
const LOG_PREF = '[menuConfig] ';

// documentation: https://material-ui.com/api/swipeable-drawer/#props
export const MENU_OPTIONS = {
  anchor: 'right',
  swipeAreaWidth: isMobile() ? 10 : 0 // default 20
};

// see https://github.com/markusenglund/react-switch#api
export const LOCATION_BUTTON_PROPS = {
  onColor: '#86d3ff',
  onHandleColor: '#2693e6',
  handleDiameter: 22,
  uncheckedIcon: false,
  checkedIcon: false,
  boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.6)',
  activeBoxShadow: '0px 0px 1px 10px rgba(0, 0, 0, 0.2)',
  height: 12,
  width: 36
};
export const PMR_BUTTON_PROPS = {
  onColor: '#86d3ff',
  onHandleColor: '#2693e6',
  handleDiameter: 22,
  uncheckedIcon: false,
  checkedIcon: false,
  boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.6)',
  activeBoxShadow: '0px 0px 1px 10px rgba(0, 0, 0, 0.2)',
  height: 12,
  width: 36
};
const ICON_SIZE = '97%';
const MEDIUM_ICON_SIZE = '85%';
const MEDIUM_SVG_SIZE = '1.7em';
const SMALL_ICON_SIZE = '35%';
const MENU_ICON_COLOR = 'F39200';
const getServiceIdByLumpMainId = (main_id, dataType) => {
  const items = Query.getTopCategories(dataType);
  let parentId;
  items && items.map(item => {
    if (item.lump.main_id === main_id) {
      parentId = item.id;
    }
  });
  return parentId;
};

/**
 * Generate a menu entry to route to Home page
 */
function getItemRouteHome(labels) {
  return createItemRoute(
  // style:
  {
    icon: {
      svgProps: {
        src: 'icons-svg/Home.svg'
      },
      style: {
        /* backgroundImage: `url("${homeIcon.replace(
          `fill='black'`,
          `fill='%23${MENU_ICON_COLOR}'`
        )}")`,
        backgroundSize: MEDIUM_ICON_SIZE,
        backgroundPosition: 'center', */
      }
    }
  },
  // label:
  labels.home.title,
  // page key:
  HOME_PAGE_KEY);
}

/**
 * Generate a menu entry to route to the list of exhibitors
 */
function getItemRouteExhibitors(labels) {
  return createItemRoute(
  // style:
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Exposants.svg'), ")")
        // backgroundSize: MEDIUM_ICON_SIZE,
        // backgroundPosition: 'center',
        // lineHeight: '1.4em',
      }
    }
  },
  // label:
  labels.menu.exhibitors,
  // page key:
  LIST_PAGE_KEY, {
    hasFilter: true,
    inputs: [{
      dataType: DATA_TYPE_EXHIBITORS
    }]
  });
}
const getActionPodcast = (labels, actions) => createItem(
// style:
{
  icon: {
    // className: 'file-icon',
    svgProps: {
      src: 'files/project/home/Exposants.svg'
    },
    style: {
      /*  backgroundImage: `url(${getUrl('files/project/home/podcast.svg')})`,
      backgroundSize: MEDIUM_ICON_SIZE,
      backgroundPosition: 'center', */
      width: MEDIUM_SVG_SIZE,
      height: MEDIUM_SVG_SIZE,
      lineHeight: '1.4em'
    }
  }
},
// label:
labels.menu.podcast,
// function to execute:
() => {
  const url = 'https://podcast.ausha.co/dans-leurs-bottes';
  if (openUrl(url, null, config.TOU_LINK_TARGET, config.TOU_LINK_OPENININAPPBROWSER)) {
    actions.linkClicked(url);
  }
});

/**
 * Generate a menu entry to route to the list of Golden Summit
 */
function getItemRouteGoldenSummitTile(labels) {
  return createItemRoute(
  // style:
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Sommet_Or.svg'), ")")
      }
    }
  },
  // label:
  labels.menu.goldensummit,
  // page key:
  LIST_PAGE_KEY, {
    inputs: [{
      dataType: DATA_TYPE_GOLDENSUMMIT_CATEGORIES
    }]
  });
}

/**
 * Generate a menu entry to route to the list of Carrefour Genetique
 */
function getItemRouteCarrefourGenetiqueTile(labels) {
  return createItemRoute(
  // style:
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Carrefour_Gen.svg'), ")")
      }
    }
  },
  // label:
  labels.menu.animals,
  // page key:
  LIST_PAGE_KEY, {
    inputs: [{
      dataType: DATA_TYPE_CARREFOURGENETIQUE_CATEGORIES
    }]
  });
}

/**
 * Generate a menu entry to route to the list of Contests
 */
function getItemRouteContestsTile(labels) {
  return createItemRoute(
  // style:
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Concours_Animaux.svg'), ")")
      }
    }
  },
  // label:
  labels.menu.contests,
  // page key:
  LIST_GROUPS_PAGE_KEY, {
    hasFilter: true,
    input: {
      dataType: DATA_TYPE_CONTESTS
    }
  });
}

/**
 * Generate a menu entry to route to the list of Job Offers
 */
function getItemRouteJobOffersTile(labels) {
  return createItemRoute(
  // style:
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Offres_Emploi.svg'), ")")
      }
    }
  },
  // label:
  labels.menu.jobOffers,
  // page key:
  LIST_PAGE_KEY, {
    hasFilter: true,
    inputs: [{
      dataType: DATA_TYPE_JOBOFFERS
    }]
  });
}

/**
 * Generate a menu entry to route to the list of projet visiteur
 */
function getItemRouteProjetVisitorTile(labels) {
  return createItemRoute(
  // style:
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Projets_Visiteurs.svg'), ")")
      }
    }
  },
  // label:
  labels.menu.userProjects,
  // page key:
  LIST_PAGE_KEY, {
    hasFilter: true,
    inputs: [{
      dataType: DATA_TYPE_USERPROJECTS
    }]
  });
}

/**
 * Generate a menu entry to route to the list of services
 */
function getItemRouteServices(labels) {
  return createItemRoute(
  // style:
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Infos_Pratiques.svg'), ")")
      }
    }
  },
  // label:
  labels.menu.services,
  // page key:
  LIST_PAGE_KEY,
  // page props:
  {
    inputs: [{
      id: getServiceIdByLumpMainId('_BY_SERVICE_', DATA_TYPE_SERVICE_CATEGORIES),
      dataType: DATA_TYPE_SERVICE_CATEGORIES
    }]
  });
}

/**
 * Generate a menu entry to route to the list of services
 */
function getItemRouteNews(labels) {
  /* return createItemRoute(
    // style:
    {
      icon: {
        style: {
          backgroundImage: `url(${getUrl('files/project/home/Actualites.svg')})`,
          backgroundSize: MEDIUM_ICON_SIZE,
          backgroundPosition: 'center',
          lineHeight: '1.4em',
        },
      },
    },
    // label:
    labels.menu.news,
    // page key:
    LIST_PAGE_KEY,
    // page props:
    {
      inputs: [
        {
          parentId: getServiceIdByLumpMainId('_BY_ACTUALITE_', DATA_TYPE_SERVICE_CATEGORIES),
          parentType: DATA_TYPE_SERVICE_CATEGORIES,
          dataType: DATA_TYPE_SERVICES,
        },
      ],
    }
  ); */
  return createItem(
  // Style
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Actualites.svg'), ")")
      }
    }
  },
  // Label (i18n)
  labels.menu.news,
  // Action to perform on click
  () => {
    if (openUrl(config.NEWS_LINK[getLang()], null, config.NEWS_LINK_TARGET, config.NEWS_LINK_OPENININAPPBROWSER)) {
      actions.linkClicked(config.NEWS_LINK[getLang()]);
    }
  });
}

/**
 * Generate a menu entry to route to participants
 */
function getItemRouteParticipants(labels) {
  if (config.NETWORKING.FEATURE_ENABLED !== true) {
    return null;
  }
  const isAllMode = config.NETWORKING.PARTICIPANTS_DATA_MODE === 'ALL';
  return createItemRoute(
  // style:
  {
    icon: {
      svgProps: {
        src: 'files/project/home/Projets_Visiteurs.svg'
      },
      style: {
        // backgroundImage: `url(${getUrl('files/project/home/Mise-en-relation.svg')})`,
        // backgroundSize: MEDIUM_ICON_SIZE,
        width: MEDIUM_SVG_SIZE,
        height: MEDIUM_SVG_SIZE,
        lineHeight: '1.4em'
      }
    }
  },
  // label:
  labels.menu.miseEnRelation,
  // page key:
  isAllMode ? LIST_PAGE_KEY : SEARCH_TAIGA_PAGE_KEY,
  // page props:
  isAllMode ? {
    inputs: [{
      dataType: DATA_TYPE_PARTICIPANTS
    }]
  } : {
    dataType: DATA_TYPE_PARTICIPANTS,
    isAdvanced: false
  });
}

/**
 * Generate a menu entry to route to list of event categories
 */
function getItemRouteAgenda(labels) {
  return createItemRoute(
  // style:
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Programme.svg'), ")")
      }
    }
  },
  // label:
  labels.menu.programme,
  // page key:
  LIST_PAGE_KEY, {
    inputs: [{
      dataType: DATA_TYPE_EVENT_CATEGORIES
    }]
  });
}
/**
 * Generate a menu entry to route to list of event categories
 */
function getItemRouteAgenda2(labels) {
  return createItemRoute(
  // style:
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Programme.svg'), ")")
      }
    }
  },
  // label:
  labels.menu.programmeSynoptic,
  // page key:
  LIST_PAGE_KEY, {
    inputs: [{
      dataType: DATA_TYPE_EVENT_CATEGORIES
    }],
    hasGoToSynopticButton: true
  });
}

/**
 * Generate a menu entry to route to the list of speakers
 */
function getItemRouteSpeakers(labels) {
  return createItemRoute(
  // style:
  {
    icon: {
      svgProps: {
        src: 'files/project/home/Projets_Visiteurs.svg'
      },
      style: {
        /* backgroundImage: `url(${getUrl('files/project/home/Intervenants.svg')})`,
        backgroundSize: ICON_SIZE, */
      }
    }
  },
  // label:
  labels.menu.speakers,
  // page key:
  LIST_PAGE_KEY,
  // page props:
  {
    inputs: [{
      dataType: DATA_TYPE_SPEAKERS
    }]
  });
}

/**
 * Generate a menu entry to route to the list of papers categories
 */
function getItemRoutePapers(labels) {
  return createItemRoute(
  // style:
  {
    icon: {
      svgProps: {
        src: 'files/project/home/Meteo.svg'
      },
      style: {
        /* backgroundImage: `url(${getUrl('files/project/home/Abstracts.svg')})`,
        backgroundSize: ICON_SIZE, */
      }
    }
  },
  // label:
  labels.menu.papers,
  // page key:
  LIST_PAGE_KEY,
  // page props:
  {
    inputs: [{
      dataType: DATA_TYPE_PAPER_CATEGORIES
    }]
  });
}

/**
 * Generate a menu entry to route to Map page
 */
function getItemRouteMobigeo(labels) {
  return createItemRoute(
  // style:
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Plan.svg'), ")")
      }
    }
  },
  // label:
  labels.menu.mobigeo,
  // page key:
  MOBIGEO_PAGE_KEY);
}

/**
 * Generate a menu entry to route to Google Map page
 */
function getItemRouteGoogleMap(labels) {
  return createItemRoute(
  // style:
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/planParis.svg'), ")")
      }
    }
  },
  // label:
  labels.menu.googleMap,
  // page key:
  GOOGLE_MAP_PAGE_KEY);
}

/**
 * Generate a menu entry to route to Notes page
 */
function getItemRouteNotes(labels) {
  return createItemRoute(
  // style:
  {
    icon: {
      // className: 'sticky-note-icon',
      svgProps: {
        src: 'icons-svg/homeToolBar/sticky-note-solid.svg'
      },
      style: {
        /*  backgroundImage: `url(${getUrl('icons-svg/homeToolBar/sticky-note-solid.svg')})`,
        backgroundSize: MEDIUM_ICON_SIZE,
        backgroundPosition: 'center', */
      }
    }
  },
  // label:
  labels.notes.pageLinkTitle,
  // page key:
  NOTES_PAGE_KEY);
}

/**
 * Generate a menu entry to route to global Search page
 */
function getItemRouteSearch(labels) {
  return createItemRoute(
  // style:
  {
    icon: {
      svgProps: {
        src: 'icons-svg/Search.svg'
      },
      style: {
        /* backgroundImage: `url("${searchIcon.replace(
          `fill='black'`,
          `fill='%23${MENU_ICON_COLOR}'`
        )}")`,
        backgroundSize: MEDIUM_ICON_SIZE,
        backgroundPosition: 'center', */
      }
    }
  },
  // label:
  labels.search.title,
  // page key:
  SEARCH_PAGE_KEY);
}

/**
 * Generate a menu entry to route to Favorites page
 */
function getItemRouteFavorites(labels) {
  return createItemRoute(
  // style:
  {
    icon: {
      svgProps: {
        src: 'icons-svg/Fav.svg'
      },
      style: {
        /*  backgroundImage: `url("${favIcon.replace(
          `fill='black'`,
          `fill='%23${MENU_ICON_COLOR}'`
        )}")`,
        backgroundSize: MEDIUM_ICON_SIZE,
        backgroundPosition: 'center', */
      }
    }
  },
  // label:
  labels.favorites.title,
  // page key:
  FAVORITES_PAGE_KEY);
}

/**
 * Generate a menu entry to route to Inbox page
 */
function getItemRouteInbox(labels) {
  return createItemRoute(
  // style:
  {
    icon: {
      // className: 'envelope-icon',
      svgProps: {
        src: 'icons-svg/homeToolBar/envelope-solid.svg'
      },
      style: {
        /* backgroundImage: `url(${getUrl('icons-svg/homeToolBar/envelope-solid.svg')})`,
        backgroundSize: MEDIUM_ICON_SIZE,
        backgroundPosition: 'center', */
        width: MEDIUM_SVG_SIZE,
        height: MEDIUM_SVG_SIZE,
        lineHeight: '1.4em'
      }
    }
  },
  // label:
  labels.menu.inbox,
  // page key:
  INBOX_PAGE_KEY);
}

/**
 * Generate a menu entry to route to Klipso Leads page
 */
function getItemRouteKlipsoLeads(labels) {
  return createItemRoute(
  // style:
  {
    icon: {
      svgProps: {
        src: 'icons-svg/KlipsoLead.svg'
      },
      style: {
        /*      backgroundImage: `url("${KLipsoIcon.replace(
          `fill='black'`,
          `fill='%23${MENU_ICON_COLOR}'`
        )}")`,
        backgroundSize: MEDIUM_ICON_SIZE,
        backgroundPosition: 'center', */
      }
    }
  },
  // label:
  labels.menu.klipsoLeads,
  // page key:
  KLIPSO_LEADS_PAGE_KEY);
}
// contactscan
function getItemRouteContactscan(labels) {
  return createItemRoute(
  // style:
  {
    icon: {
      svgProps: {
        src: 'icons-svg/KlipsoLead.svg'
      },
      style: {
        /* backgroundImage: `url("${KLipsoIcon.replace(
          `fill='black'`,
          `fill='%23${MENU_ICON_COLOR}'`
        )}")`,
        backgroundSize: MEDIUM_ICON_SIZE,
        backgroundPosition: 'center', */
        width: MEDIUM_SVG_SIZE,
        height: MEDIUM_SVG_SIZE,
        lineHeight: '1.4em'
      }
    }
  },
  // label:
  labels.menu.Contactscan,
  // page key:
  KLIPSO_LEADS_PAGE_KEY);
}
function getItemRouteBadge(labels) {
  return createItem(
  // Style
  {
    icon: {
      svgProps: {
        src: 'icons-svg/badge.svg'
      },
      style: {
        /*  backgroundImage: `url("${badgeIcon.replace(
          `fill='black'`,
          `fill='%23${MENU_ICON_COLOR}'`
        )}")`,
        backgroundSize: MEDIUM_ICON_SIZE,
        backgroundPosition: 'center', */
      }
    }
  },
  // Label (i18n)
  labels.menu.ebadge,
  // Action to perform on click
  () => {
    if (openUrl(config.BADGE_LINK[getLang()], null, config.BADGE_LINK_TARGET, config.BADGE_LINK_OPENININAPPBROWSER)) {
      actions.linkClicked(config.BADGE_LINK[getLang()]);
    }
  });
}

/**
 * Generate a menu entry to change app language
 */
function getItemLang(labels, actions) {
  return createItem(
  // style:
  {
    icon: {
      svgProps: {
        src: 'icons-svg/langue.svg'
      },
      style: {
        /* backgroundImage: `url(${getUrl('icons-svg/fiche/globe-solid.svg')})`,
        backgroundSize: MEDIUM_ICON_SIZE,
        backgroundPosition: 'center', */
      }
    }
  },
  // label:
  labels.menu.language,
  // function to execute:
  actions.showChooseLangDialog);
}

/**
 * Generate a menu entry to open TOU link
 */
const getActionTOU = (labels, actions) => createItem(
// style:
{
  icon: {
    // className: 'file-icon',
    svgProps: {
      src: 'icons-svg/cgu.svg'
    },
    style: {
      /*  backgroundImage: `url(${getUrl('files/project/home/cgu.svg')})`,
      backgroundSize: MEDIUM_ICON_SIZE,
      backgroundPosition: 'center', */
    }
  }
},
// label:
labels.menu.cgu,
// function to execute:
() => {
  if (openUrl(config.TOU_LINK[labels.id], null, config.TOU_LINK_TARGET, config.TOU_LINK_OPENININAPPBROWSER)) {
    actions.linkClicked(config.TOU_LINK[labels.id]);
  }
});

/**
 * Generate a menu entry to open TOU link
 */

/**
 * Generate a menu entry to open TOU link
 */
const getActionBadge = labels => createItem(
// style:
{
  icon: {
    style: {
      backgroundImage: "url(".concat(getUrl('files/project/home/badge.svg'), ")")
    }
  }
},
// label:
labels.menu.ebadge,
// page key:
KLIPSO_LEADS_PAGE_KEY,
// page props:
null);
const getUserData = labels => createItemRoute(
// style:
{
  icon: {
    // className: 'user-icon',
    svgProps: {
      src: 'icons-svg/Profil.svg'
    },
    style: {
      /*  backgroundImage: `url(${getUrl('icons-svg/Profil.svg')})`,
      backgroundSize: MEDIUM_ICON_SIZE,
      backgroundPosition: 'center', */
    }
  }
},
// label:
labels.menu.userData,
// page key:
USER_DATA_PAGE_KEY,
// page props:
null);

/**
 * Generate a menu entry to route to Choose profile page
 */
function getItemRouteChooseProfile(labels, profile) {
  return createItemRoute(
  // style:
  {
    icon: {
      // className: 'user-icon',
      svgProps: {
        src: 'icons-svg/fiche/groupe.svg'
      },
      style: {
        /*  backgroundImage: `url(${getUrl('icons-svg/Profil.svg')})`,
        backgroundSize: MEDIUM_ICON_SIZE,
        backgroundPosition: 'center', */
      }
    }
  },
  // label:
  (profile ? "<div class=\"menu-text-current-profile\">".concat(profile, "</div>") : '') + labels.menu.toggleProfile,
  // page key:
  CHOOSE_PROFILE_PAGE_KEY,
  // Page props:
  null);
}

/**
 * Generate a menu entry using configuration from a config.json menubuttons item
 */
function getItemRouteWeather(labels, actions) {
  return createItem(
  // Style
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Meteo.svg'), ")")
      }
    }
  },
  // Label (i18n)
  labels.menu.weather, () => {
    if (openUrl(config.WEATHER_LINK, null, config.WEATHER_LINK_TARGET, config.WEATHER_LINK_OPENININAPPBROWSER)) {
      actions.linkClicked(config.WEATHER_LINK);
    }
  });
}
function getAdMenuButton(conf, labels, actions) {
  if (!conf) {
    return null;
  }
  return createItem(
  // style:
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl(conf["img_".concat(labels.id)]), ")"),
        backgroundSize: ICON_SIZE
      }
    }
  },
  // label:
  conf["label_".concat(labels.id)],
  // function to execute:
  () => {
    const url = conf["link_".concat(labels.id)];
    if (openUrl(url)) {
      actions.linkClicked(url);
    }
  });
}

/**
 * Generate a menu entry to route to Social Media page
 */
function getItemSocialMedia(labels) {
  return createItemRoute(
  // style:
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Reseaux_Sociaux.svg'), ")"),
        backgroundSize: ICON_SIZE
      }
    }
  },
  // label:
  labels.menu.medias,
  // page key:
  MEDIAS_PAGE_KEY,
  // page props:
  {
    controlMediaPageContent: {
      hideTabMediaSocial: true
      // mediaTabKey: 'twitter',
    }
  });
}

/**
 * Generate a menu entry to route to Video page
 */

function getItemRouteVideos(labels) {
  return createItemRoute(
  // style:
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Reseaux_Sociaux.svg'), ")")
      }
    }
  },
  // label:
  labels.menu.videos,
  // page key:
  LIST_PAGE_KEY,
  // page props:
  {
    inputs: [{
      dataType: DATA_TYPE_VIDEO_CATEGORIES
    }]
  });
}
/**
 * Generate a button to toggle location
 */
function getItemToggleLocation(labels) {
  return createItemToggleLocation(
  // style:
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/location.svg'), ")"),
        backgroundSize: ICON_SIZE
      }
    }
  },
  // label:
  labels.menu.location);
}
function getItemRouteMarketPlaceTile(labels) {
  return createItemRoute(
  // style:
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Market_Place.svg'), ")")
      }
    }
  },
  // label:
  labels.menu.marketplace,
  // page key:
  LIST_PAGE_KEY, {
    hasFilter: true,
    inputs: [{
      dataType: DATA_TYPE_NEWPRODUCTS
    }]
  });
}

/**
 * Generate a button to toggle PMR status (used for mobigeo routing)
 */
export function getItemTogglePMR(labels) {
  return createItemTogglePMR(
  // style:
  {
    icon: {
      svgProps: {
        src: 'icons-svg/pmr.svg'
      },
      style: {
        /* backgroundImage: `url("${homeIcon.replace(
          `fill='black'`,
          `fill='%23${MENU_ICON_COLOR}'`
        )}")`,
        backgroundSize: MEDIUM_ICON_SIZE,
        backgroundPosition: 'center', */
      }
    }
  },
  // label:
  labels.menu.pmr);
}

/**
 * Generate an item to open the current page in the mobile app
 * for deeplinking test purpose
 */
/* function getItemOpenCurrentPageInNativeApp() {
    return createItem(
        // style
        { icon: {
            className: ''+(isIOS() ? 'apple-icon' : 'android-icon'),
            style: {
                backgroundColor: '#bababa',
                fontSize: '2em',
            }
        }},
        // label:
        'Open page in '+(isIOS() ? 'iOS' : 'android')+' app',
        // function to execute:
        openCurrentPageInApp,
    );
} */

/**
 * Menu configuration
 */
// const getConfig = (actions, labels, adConfig, twoColumns) => {
//     let conf = {
//         default: [
//             getItemRouteBrands(labels),
//             getItemRouteRestaurants(labels),
//             getItemRouteServices(labels),
//             getItemRouteHours(labels),
//             getItemRouteMobigeo(labels),
//             // Generic:
//             getItemRouteSearch(labels),
//             getItemRouteFavorites(labels),
//             getItemRouteInbox(labels),
//             getItemLang(labels, actions),
//             config.ENV === 'dev' && global.isCordovaContext !== true ? getItemOpenCurrentPageInNativeApp() : null,
//         ]
//     };
//
//     if (adConfig && adConfig.menubuttons) {
//         Object.keys(adConfig.menubuttons).forEach(menuBtnKey => {
//             conf.default.push(getAdMenuButton(adConfig.menubuttons[menuBtnKey], labels, actions) );
//         });
//     }
//
//     // Filter any empty entry
//     Object.keys(conf).forEach(function(profile) {
//         conf[profile] = conf[profile].filter(menuItemConfig => menuItemConfig);
//     });
//
//     return conf;
// };

/**
 * Menu configuration for default profile
 */
const getDefaultConfig = (actions, labels, adConfig, profile) => {
  const conf = {
    default: [
    // Home
    // getItemRouteHome(labels),

    // Exposants
    getItemRouteExhibitors(labels),
    // MarketPlace
    getItemRouteMarketPlaceTile(labels),
    // getItemRouteMarketPlaceTile(labels),
    // getItemRoutePapers(labels),
    // Programme
    getItemRouteAgenda(labels),
    // Programme categories,
    // getItemRouteAgenda2(labels),
    // Intervenants
    // getItemRouteSpeakers(labels),
    // Plan
    getItemRouteMobigeo(labels),
    // getItemRouteGoogleMap(labels),

    // Sommet d’or
    getItemRouteGoldenSummitTile(labels),
    // carrefour genetiques
    getItemRouteCarrefourGenetiqueTile(labels), getItemRouteContestsTile(labels),
    // Offres d’emploi

    getItemRouteJobOffersTile(labels),
    // projet visitor
    getItemRouteProjetVisitorTile(labels),
    // getItemRouteParticipants(labels),
    // Concours Animaux

    // Actualites
    getItemRouteNews(labels),
    // INFOS PRATIQUES
    getItemRouteServices(labels),
    // VIDEOS
    getItemRouteVideos(labels),
    // podcast
    getActionPodcast(labels),
    // Social Medias
    getItemSocialMedia(labels),
    // Weather
    getItemRouteWeather(labels, actions),
    // Favorites
    getItemRouteFavorites(labels),
    // Notes
    config.NOTES && config.NOTES.FEATURE_ENABLED ? getItemRouteNotes(labels) : null,
    // Search
    getItemRouteSearch(labels),
    // Lang
    getItemLang(labels, actions),
    // ebadge
    // getItemRouteBadge(labels),

    // Notifications
    getItemRouteInbox(labels),
    // Choisir profil
    getItemRouteChooseProfile(labels, profile)]
  };
  if (adConfig && adConfig.menubuttons) {
    Object.keys(adConfig.menubuttons).forEach(menuBtnKey => {
      conf.default.push(getAdMenuButton(adConfig.menubuttons[menuBtnKey], labels, actions));
    });
  }
  conf.default.push(getActionTOU(labels, actions));
  return conf;
};

/**
 * Menu configuration for GP profile
 */
const getWebAppExhConfig = (actions, labels, adConfig, profile) => {
  const conf = {
    default: [
    // exposants
    getItemRouteExhibitors(labels),
    // Favorites
    getItemRouteFavorites(labels),
    // Notes
    config.NOTES && config.NOTES.FEATURE_ENABLED ? getItemRouteNotes(labels) : null,
    // Search
    getItemRouteSearch(labels),
    // Notifications
    getItemRouteInbox(labels),
    // getItemRouteExhibitors(labels),
    getItemRouteMobigeo(labels), getItemLang(labels, actions),
    // getItemSocialMedia(labels),
    getItemRouteChooseProfile(labels, profile)]
  };
  if (adConfig && adConfig.menubuttons) {
    Object.keys(adConfig.menubuttons).forEach(menuBtnKey => {
      conf.default.push(getAdMenuButton(adConfig.menubuttons[menuBtnKey], labels, actions));
    });
  }
  return conf;
};
/**
 * Menu configuration for PRESS profile
 */
const getWebAppEvtConfig = (actions, labels, adConfig, profile) => {
  const conf = {
    default: [
    // Favorites
    getItemRouteFavorites(labels),
    // Notes
    config.NOTES && config.NOTES.FEATURE_ENABLED ? getItemRouteNotes(labels) : null,
    // Search
    getItemRouteSearch(labels),
    // Lang
    getItemLang(labels, actions),
    // Notifications
    getItemRouteInbox(labels), getItemRouteAgenda(labels),
    // getItemRouteSpeakers(labels),
    getItemRouteMobigeo(labels), getItemRouteChooseProfile(labels, profile)]
  };
  if (adConfig && adConfig.menubuttons) {
    Object.keys(adConfig.menubuttons).forEach(menuBtnKey => {
      conf.default.push(getAdMenuButton(adConfig.menubuttons[menuBtnKey], labels, actions));
    });
  }
  return conf;
};
const getVisitor = (actions, labels, adConfig, profile) => {
  const conf = {
    default: [getItemRouteContactscan(labels),
    // getItemRouteMarketPlaceTile(labels),
    getItemRoutePapers(labels),
    // Programme
    getItemRouteAgenda(labels),
    // Programme categories,
    getItemRouteAgenda2(labels),
    // Intervenants
    getItemRouteSpeakers(labels),
    // Plan
    getItemRouteMobigeo(labels),
    // getItemRouteGoldenSummitTile(labels),
    getItemRouteParticipants(labels),
    // Actualites
    getItemRouteNews(labels),
    // Infos pratiques
    getItemRouteServices(labels),
    // Social medias
    getItemSocialMedia(labels),
    // Favorites
    getItemRouteFavorites(labels),
    // Notes
    config.NOTES && config.NOTES.FEATURE_ENABLED ? getItemRouteNotes(labels) : null,
    // Search
    getItemRouteSearch(labels),
    // Lang
    getItemLang(labels, actions),
    // Notifications
    getItemRouteInbox(labels),
    // Changer profile
    getItemRouteChooseProfile(labels, profile)]
  };
  if (adConfig && adConfig.menubuttons) {
    Object.keys(adConfig.menubuttons).forEach(menuBtnKey => {
      conf.default.push(getAdMenuButton(adConfig.menubuttons[menuBtnKey], labels, actions));
    });
  }
  return conf;
};
const getExhibitor = (actions, labels, adConfig, profile) => {
  const conf = {
    default: [
    // KlipsoLeads
    getItemRouteKlipsoLeads(labels),
    // exposants
    getItemRouteExhibitors(labels),
    // getItemRouteMarketPlaceTile(labels),
    getItemRoutePapers(labels),
    // Programme
    getItemRouteAgenda(labels),
    // Programme categories,
    getItemRouteAgenda2(labels),
    // Intervenants
    getItemRouteSpeakers(labels),
    // Plan
    getItemRouteMobigeo(labels),
    // getItemRouteGoldenSummitTile(labels),
    getItemRouteParticipants(labels),
    // Actualites
    getItemRouteNews(labels),
    // Infos pratiques
    getItemRouteServices(labels),
    // Social medias
    getItemSocialMedia(labels),
    // Favorites
    getItemRouteFavorites(labels),
    // Notes
    config.NOTES && config.NOTES.FEATURE_ENABLED ? getItemRouteNotes(labels) : null,
    // Search
    getItemRouteSearch(labels),
    // Lang
    getItemLang(labels, actions),
    // Notifications
    getItemRouteInbox(labels),
    // Changer profile
    getItemRouteChooseProfile(labels, profile)]
  };
  if (adConfig && adConfig.menubuttons) {
    Object.keys(adConfig.menubuttons).forEach(menuBtnKey => {
      conf.default.push(getAdMenuButton(adConfig.menubuttons[menuBtnKey], labels, actions));
    });
  }
  return conf;
};
/**
 * Menu configuration for PRESS profile
 */
const getWebAppSpkConfig = (actions, labels, adConfig, profile) => {
  const conf = {
    default: [getItemRouteFavorites(labels), getItemRouteSpeakers(labels), getItemRouteAgenda(labels), getItemRouteChooseProfile(labels, profile)]
  };
  if (adConfig && adConfig.menubuttons) {
    Object.keys(adConfig.menubuttons).forEach(menuBtnKey => {
      conf.default.push(getAdMenuButton(adConfig.menubuttons[menuBtnKey], labels, actions));
    });
  }
  return conf;
};

/**
 * Return menu configuration
 * @param  {string} profile
 * @return {object}
 */
export const get = function () {
  let profile = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : DEFAULT_PROFILE;
  let page = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'default';
  let actions = arguments.length > 2 ? arguments[2] : undefined;
  let labels = arguments.length > 3 ? arguments[3] : undefined;
  let adConfig = arguments.length > 4 ? arguments[4] : undefined;
  let twoColumns = arguments.length > 5 ? arguments[5] : undefined;
  let conf;
  // Get menu config depending on user profile
  switch (profile) {
    case DEFAULT_PROFILE:
      conf = getDefaultConfig(actions, labels, adConfig, labels.profile[profile]);
      break;
    case WEBAPP_EXH_PROFILE:
      conf = getWebAppExhConfig(actions, labels, adConfig, labels.profile[profile]);
      break;
    case WEBAPP_EVT_PROFILE:
      conf = getWebAppEvtConfig(actions, labels, adConfig, labels.profile[profile]);
      break;
    case VISITOR_PROFILE:
      conf = getVisitor(actions, labels, adConfig, labels.profile[profile]);
      break;
    case EXHIBITOR_PROFILE:
      conf = getExhibitor(actions, labels, adConfig, labels.profile[profile]);
      break;
    case WEBAPP_SPK_PROFILE:
      conf = getWebAppSpkConfig(actions, labels, adConfig, labels.profile[profile]);
      break;
    case WEBAPP_MAP:
      return;
      break;
    default:
      console.error("".concat(LOG_PREF, "Unsupported profile"), profile);
  }

  // Return config depending on page
  if (conf) {
    // Filter any empty entry
    Object.keys(conf).forEach(page => {
      conf[page] = conf[page].filter(menuItemConfig => menuItemConfig);
    });
    return conf[page] || conf.default;
  }
  // else undefined is returned
};
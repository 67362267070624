import React from 'react';

import { getValueFromStringAttribute } from 'src/core/util/JsTools';
import renderToggleButton from './renderToggleButton';
import renderField from './renderField';

const LOG_PREF = '[SearchForm renderSection] ';

export default function ({
  index,
  section,
  fieldsHooks,
  performSearch,
  isSearchOngoing,
  labels,
  setFieldVisiblity,
  toggleButtonsVisiblityHooks,
}) {
  return (
    <section key={index} className={section.className || null}>
      {section.label && <label>{getValueFromStringAttribute(labels, section.label)}</label>}

      {/* BUTTONS */}
      {(section.buttons || []).map((toggleButtonConfig, index) => {
        switch (toggleButtonConfig.type) {
          case 'TOGGLE':
            return renderToggleButton({
              key: index,
              toggleButtonConfig,
              fieldsHooks,
              performSearch,
              isSearchOngoing,
              labels,
              setFieldVisiblity,
              toggleButtonsVisiblityHooks,
            });

          default:
            console.error(`${LOG_PREF}Unmanaged button type ${toggleButtonConfig.type}`);
            return null;
        }
      })}

      {/* FIELDS */}
      {section.fields &&
        Object.keys(section.fields).map((fieldName) =>
          renderField({
            fieldName,
            fieldConf: section.fields[fieldName],
            fieldsHooks,
            performSearch,
            isSearchOngoing,
            labels,
            setFieldVisiblity,
            displayLabel: section.displayLabelInput,
            displayIcon: section.displayIcon,
          })
        )}
    </section>
  );
}

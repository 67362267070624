import React from 'react';

import './Content.scss';

function Content({ children }) {
  return (
    <div className="kl-content-wrapper">
      <div className="kl-content content-font">{children}</div>
    </div>
  );
}

export default Content;

import React from 'react';
import PropTypes from 'prop-types';
import Measure from 'react-measure';
import { debounce } from 'lodash-custom';

import { EVENT_PAGE_KEY } from 'src/pages/pagesKeys';

import { simpleSort, sortStrings } from 'src/core/util/sortUtil';
import { colorCheck } from 'src/core/util/StringUtil';

import './SynopticAgendaGrid.scss';

// Declare how big an hour is represented (pixels)
const HOUR_SCALE = 150;

// Text will be vertically aligned to the top if event container height is below
const HEIGHT_TEXT_ALIGN_THRESHOLD = 125;

const STYLE = {
  eventBorderBottomWidth: 2, // BEWARE: also defined in scss file
};

const getHourFromStringTime = (stringTime) => parseInt(stringTime.substring(0, 2), 10);
const getMinutesFromStringTime = (stringTime) => parseInt(stringTime.substring(3), 10);

// const getCoordinatesFromMouseEvent = e => ({ x: e.pageX, y: e.pageY })

class SynopticAgendaGrid extends React.Component {
  state = {};

  sortHours = () => {
    let hours = [];
    this.props.events.forEach((event) => {
      hours.push(getHourFromStringTime(event.start_time));
      hours.push(getHourFromStringTime(event.end_time) + 1);
    });

    // Remove duplicates
    hours = [...new Set(hours)];

    // Sort
    hours = hours.sort(simpleSort);

    // Avoid updating state
    if (this.state.hours && JSON.stringify(this.state.hours) === JSON.stringify(hours)) {
      return;
    }

    this.setState({ hours });
  };

  componentDidUpdate() {
    this.sortHours();
  }

  setGridRef = (el) => {
    this.gridEl = el;
  };

  setPlacesContainerRef = (el) => {
    this.placesContainerEl = el;
  };

  setHoursContainerRef = (el) => {
    this.hoursContainerEl = el;
  };

  onContentResize = debounce((measure) => {
    if (this.props.isPageVisible()) {
      let newContentHeight = document.documentElement.clientHeight - measure.bounds.top;
      if (newContentHeight !== this.state.contentHeight) {
        this.setState({ contentHeight: newContentHeight });
      }
    }
  }, 50);

  /*onMouse = (function(_this) {
        let rafRunning = false,
            mouseDown = false,
            mouseMoved = false,
            lastCoord,
            diff;

        const onMouseDown = e => {
            console.log('onMouseDown ');
            mouseDown = true;
            mouseMoved = false;
            lastCoord = getCoordinatesFromMouseEvent(e);
        }

        const onMouseMove = e => {
            if (mouseDown && lastCoord) {
                mouseMoved = true;
                let newCoords = getCoordinatesFromMouseEvent(e);
                diff = {
                    x: lastCoord.x - newCoords.x,
                    y: lastCoord.y - newCoords.y,
                };
                lastCoord = newCoords;
                console.log('onMouseMove ', diff);

                if (rafRunning) {
                    return;
                }
                window.requestAnimationFrame(_perform);
                rafRunning = true;
            }
        }

        const _perform = () => {
            if (_this.placesContainerEl && _this.gridEl && diff && typeof diff.x === 'number') {
                // FIXME: prevent negative value
                _this.placesContainerEl.style.left = _this.placesContainerEl.offsetLeft-diff.x+_this.gridEl.offsetLeft+'px';
                // FIXME
                _this.contentContainerEl.style.left = _this.contentContainerEl.offsetLeft-diff.x+_this.gridEl.offsetLeft+'px';;
            }
            if (_this.hoursContainerEl && diff && typeof diff.y === 'number') {
                // FIXME: prevent negative value
                _this.hoursContainerEl.style.top = _this.hoursContainerEl.offsetTop-diff.y+'px';
                // FIXME
                _this.contentContainerEl.style.top = _this.contentContainerEl.offsetTop-diff.y+'px';
            }
            rafRunning = false;
        }

        const onMouseUp = e => {
            console.log('onMouseUp ');
            mouseDown = false;
            if (mouseMoved) {
                // Prevent clic on event when releasing the mouse after content drag
                e.stopPropagation();
            }
        }

        return {
            down: onMouseDown,
            move: onMouseMove,
            up: onMouseUp,
        };
    })(this);*/

  onScroll = (function (_this) {
    let rafRunning = false,
      lastScrollLeft,
      lastScrollTop;

    const perform = (e) => {
      lastScrollLeft = e.target.scrollLeft;
      lastScrollTop = e.target.scrollTop;

      if (rafRunning) {
        return;
      }
      window.requestAnimationFrame(_perform);
      rafRunning = true;
    };

    const _perform = (e) => {
      if (_this.placesContainerEl && _this.gridEl && typeof lastScrollLeft === 'number') {
        _this.placesContainerEl.style.transform = 'translateX(' + -lastScrollLeft + 'px)';
      }
      if (_this.hoursContainerEl && typeof lastScrollTop === 'number') {
        _this.hoursContainerEl.style.transform = 'translateY(' + -lastScrollTop + 'px)';
      }
      rafRunning = false;
    };

    return perform;
  })(this);

  placeRenderer = (placeLabel, index) => (
    <span key={placeLabel} className="sa-grid-place">
      <span>{placeLabel}</span>
    </span>
  );

  hourRenderer = (hour) => (
    <div key={hour} className="sa-grid-hour" style={{ height: HOUR_SCALE - 1 /*border*/ }}>
      <div>{hour}</div>
    </div>
  );

  columnRenderer = (placeLabel, index) => {
    let events = this.props.eventsByPlaces[placeLabel],
      previousEventsCumulatedHeight = 0;

    return (
      <div
        key={placeLabel}
        className="sa-grid-events-column"
        style={{ height: this.state.hours.length * HOUR_SCALE }}
      >
        {events.map((eventId) => {
          if (!eventId) {
            return null;
          }
          let event = this.getEvent(eventId);
          if (!event) {
            return null;
          }

          let startHour = getHourFromStringTime(event.start_time),
            startMinutes = getMinutesFromStringTime(event.start_time),
            endHour = getHourFromStringTime(event.end_time),
            endMinutes = getMinutesFromStringTime(event.end_time),
            top =
              (startHour - this.state.hours[0]) * HOUR_SCALE +
              (startMinutes * HOUR_SCALE) / 60 -
              previousEventsCumulatedHeight +
              STYLE.eventBorderBottomWidth / 2,
            height =
              (endHour - startHour) * HOUR_SCALE +
              ((endMinutes - startMinutes) * HOUR_SCALE) / 60 -
              STYLE.eventBorderBottomWidth;

          previousEventsCumulatedHeight += height + STYLE.eventBorderBottomWidth;

          let style = {
            top: top + 'px',
            height: height + 'px',
            backgroundColor:
              event.lump?.color && colorCheck(event.lump?.color)
                ? event.lump?.color
                : this.props.synoConfig.SYNOPTIC_DEFAULT_EVENT_BGCOLOR,
          };
          let textStyle = null;
          if (height < HEIGHT_TEXT_ALIGN_THRESHOLD) {
            textStyle = {
              justifyContent: 'start',
            };
          }
          return this.eventRenderer(event, style, textStyle);
        })}
      </div>
    );
  };

  eventRenderer = (event, style, textStyle) => (
    <div
      key={event.id}
      data-event-id={event.id}
      style={style}
      className="sa-grid-event"
      onClick={this.navigateToEvent}
    >
      <div>
        <div className="sa-grid-event-time">
          {event.start_time} ➞ {event.end_time}
        </div>
        <div className="sa-grid-event-desc" style={textStyle}>
          {event.references.eventType && (
            <span className="sa-grid-event-type">{event.references.eventType.title}</span>
          )}
          <span>{event.title}</span>
        </div>
      </div>
    </div>
  );

  getEvent = (eventId) => this.props.events.find((_event) => _event.id === eventId);

  navigateToEvent = (e) => {
    this.props.actions.navigate(EVENT_PAGE_KEY, { id: parseInt(e.target.dataset.eventId, 10) });
  };

  render() {
    if (!Array.isArray(this.state.hours)) {
      return null;
    }

    let hoursEls = [];
    for (var i = this.state.hours[0]; i < this.state.hours[this.state.hours.length - 1] + 1; i++) {
      hoursEls.push(this.hourRenderer(i));
    }

    let placeLabels = Object.keys(this.props.eventsByPlaces).sort(sortStrings);

    return (
      <div className="sa-grid-container">
        <div className="sa-grid-places" ref={this.setPlacesContainerRef}>
          {placeLabels.map(this.placeRenderer)}
        </div>

        <div className="sa-grid-hours" ref={this.setHoursContainerRef}>
          {hoursEls}
        </div>

        <div
          className="sa-grid scrollbars-hidden"
          ref={this.setGridRef}
          onScroll={this.onScroll}
          // onMouseDown={this.onMouse.down}
          // onMouseMove={this.onMouse.move}
          // onMouseUp={this.onMouse.up}
        >
          <Measure bounds onResize={this.onContentResize}>
            {({ measureRef }) => (
              <div
                ref={measureRef}
                className="sa-grid-content"
                style={{ height: this.state.contentHeight }}
              >
                {placeLabels.map(this.columnRenderer)}
              </div>
            )}
          </Measure>
        </div>
      </div>
    );
  }
}

SynopticAgendaGrid.propTypes = {
  synoConfig: PropTypes.object,
  events: PropTypes.array.isRequired,
  eventsByPlaces: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default SynopticAgendaGrid;

import { HELPERS_PAGE_KEY } from 'src/pages/pagesKeys';
import config from 'app-customs/config/config';
import HelpersPage from './HelpersPage';
import reducer from './HelpersReducer';

export default {
  key: HELPERS_PAGE_KEY,
  path: '/helpers',
  component: HelpersPage,
  elId: 'helpers-page',
  getReduxReducer: (state, action) => reducer(state[HELPERS_PAGE_KEY], action),
  isGranted: () => config?.HELPERS?.FEATURE_ENABLED,
};

import React from 'react';
import PropTypes from 'prop-types';

import { performScan } from 'src/core/klipso-leads/KlipsoLeadsBadgeHelper';

import GenericIcon from 'src/components/generic-icon/GenericIcon';

import FooterGenericButton from './FooterGenericButton';

function FooterScanButton({ disabled, disabledMessage, actions, labels, callback }) {
  function onClick() {
    if (disabled) {
      if (disabledMessage) {
        actions.showNotification({ message: disabledMessage });
      }
      return;
    }
    performScan({ actions, labels, callback });
  }

  return (
    <FooterGenericButton className="klf-scan-btn" disabled={!!disabled} callback={onClick}>
      <GenericIcon className="qr-code-icon " title="scan button icon" />
    </FooterGenericButton>
  );
}

FooterScanButton.propTypes = {
  disabled: PropTypes.bool,
  disabledMessage: PropTypes.string,
  actions: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
};

export default FooterScanButton;

import { PAPER_PAGE_KEY } from 'src/pages/pagesKeys';

import GenericItemPage, { DOM_ID } from 'src/pages/generic-item-page/GenericItemPage';
import PaperContent from './PaperContent';

export default {
  key: PAPER_PAGE_KEY,
  path: '/paper',
  elId: DOM_ID,
  className: 'paper-page',
  component: GenericItemPage,
  childComponent: PaperContent,
  relatedDataToFetch: ['cats', 'events', 'speakers'],
};

import { event_Exsist, add_Event_calendars } from './calendarPersistence';

export async function hasReadPermission(onSuccess, onError) {
  await window.plugins.calendar.hasReadPermission(onSuccess, onError);
}

export async function requestReadPermission(onSuccess, onError) {
  await window.plugins.calendar.requestReadPermission(onSuccess, onError);
}

export async function hasWritePermission(onSuccess, onError) {
  await window.plugins.calendar.hasWritePermission(onSuccess, onError);
}

export async function requestWritePermission(onSuccess, onError) {
  await window.plugins.calendar.requestWritePermission(onSuccess, onError);
}

export async function hasReadWritePermission(onSuccess, onError) {
  await window.plugins.calendar.hasReadWritePermission(onSuccess, onError);
}

export async function requestReadWritePermission(onSuccess, onError) {
  await window.plugins.calendar.requestReadWritePermission(onSuccess, onError);
}

// on iOS the success handler receives the event ID
// var existingEvent;
/* static data to test

var startDate = new Date(2022, 11, 15, 18, 30, 0, 0, 0); // beware: month 0 = january, 11 = december
var endDate = new Date(2022, 12, 15, 19, 30, 0, 0, 0);
var title = 'tunis leni ahlem event';
var eventLocation = 'Home';
var notes = 'Some notes about this event.';
var options = {};
*/

// create an event silently
export function createCalendarEvent(
  title,
  eventLocation,
  notes,
  startDate,
  endDate,
  success,
  error
) {
  window.plugins.calendar.createEvent(
    title,
    eventLocation,
    notes,
    startDate,
    endDate,
    success,
    error
  );
}

// on iOS the success handler receives the event ID
export function createCalendarEventWithOptions(
  title,
  loc,
  notes,
  startDate,
  endDate,
  options,
  success,
  error
) {
  window.plugins.calendar.createEventWithOptions(
    title,
    loc,
    notes,
    startDate,
    endDate,
    options,
    success,
    error
  );
}

export function onErrorEvent(actions, labels, NotificationLevels, error) {
  if (error) {
    actions.showNotification({
      message: labels.exportEvent.exportFail,
      level: NotificationLevels.ERROR,
    });
    console.error(`Calendar Service Error: ${error}`);
  }
}

export function onSuccessEvent(actions, labels, NotificationLevels, message, next) {
  next && next();
  actions.showNotification({
    message: labels.exportEvent.exportSuccess,
    level: NotificationLevels.SUCCESS,
  });
  message && console.info(`Calendar Service Success ${JSON.stringify(message)}`);
}

export function onEventExsiste(actions, labels, NotificationLevels) {
  actions.showNotification({
    message: labels.exportEvent.alreadyExported,
    level: NotificationLevels.SUCCESS,
  });
  console.info('Calendar Service already created ');
}

export function onCreateCalendarEventWithOptions(
  id,
  title,
  eventLocation,
  notes,
  startDate,
  endDate,
  options,
  actions,
  labels,
  NotificationLevels
) {
  if (!event_Exsist(id)) {
    createCalendarEventWithOptions(
      title,
      eventLocation,
      notes,
      startDate,
      endDate,
      options,
      (message) =>
        onSuccessEvent(actions, labels, NotificationLevels, message, add_Event_calendars(id)),
      (error) => onErrorEvent(actions, labels, NotificationLevels, error)
    );
  } else {
    onEventExsiste(actions, labels, NotificationLevels);
  }
}
// create an event interactively
export function createCalendarEventInteractively(
  title,
  eventLocation,
  notes,
  startDate,
  endDate,
  success,
  error
) {
  window.plugins.calendar.createEventInteractively(
    title,
    eventLocation,
    notes,
    startDate,
    endDate,
    success,
    error
  );
}

// create an event interactively with the calOptions object as shown above
export function createCalendarEventInteractivelyWithOptions(
  title,
  eventLocation,
  notes,
  startDate,
  endDate,
  options,
  success,
  error
) {
  window.plugins.calendar.createEventInteractivelyWithOptions(
    title,
    eventLocation,
    notes,
    startDate,
    endDate,
    options,
    success,
    error
  );
}

// delete an event (you can pass nulls for irrelevant parameters). The dates are mandatory and represent a date range to delete events in.
// note that on iOS there is a bug where the timespan must not be larger than 4 years, see issue 102 for details.. call this method multiple times if need be
export function deleteEvent(title, eventLocation, notes, startDate, endDate, success, error) {
  window.plugins.calendar.deleteEvent(
    title,
    eventLocation,
    notes,
    startDate,
    endDate,
    success,
    error
  );
}

export function listCalendars(success, error) {
  window.plugins.calendar.listCalendars(success, error);
}

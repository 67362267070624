import { DATA_TYPE_EVENT_CATEGORIES, DATA_TYPE_EXHIBITORS,
// DATA_TYPE_EXHIBITOR_CATEGORIES,
DATA_TYPE_EVENTS, DATA_TYPE_CONTESTS, DATA_TYPE_PARTICIPANTS, DATA_TYPE_SPEAKERS, DATA_TYPE_SERVICES, DATA_TYPE_SERVICE_CATEGORIES, DATA_TYPE_PAPERS, DATA_TYPE_PAPER_CATEGORIES, DATA_TYPE_STORE_CATEGORIES, DATA_TYPE_NEWPRODUCTS, DATA_TYPE_USERPROJECTS, DATA_TYPE_GOLDENSUMMIT_CATEGORIES,
// DATA_TYPE_USERPROJECT_CATEGORIES,
DATA_TYPE_JOBOFFER_CATEGORIES, DATA_TYPE_JOBOFFERS, DATA_TYPE_NEWPRODUCT_CATEGORIES } from 'app-customs/config/dataConfig';
import config from 'app-customs/config/config';
import { LIST_PAGE_KEY, LIST_GROUPS_PAGE_KEY,
// LOGIN_PAGE_KEY,
MOBIGEO_PAGE_KEY, GOOGLE_MAP_PAGE_KEY, MEDIAS_PAGE_KEY, SEARCH_TAIGA_PAGE_KEY, INBOX_PAGE_KEY, HELPERS_PAGE_KEY } from 'src/pages/pagesKeys';
import AdSwap from 'src/components/ad-swap/AdSwap';
import HomeToolbar from 'src/pages/home/HomeToolbar';
import HomeTiles from 'src/pages/home/HomeTiles';
import MobilespotBar from 'src/components/mobilespot-bar/MobilespotBar';
import IphonexFlexMargin from 'src/components/iphonex-flex-margin/IphonexFlexMargin';
import { getUrl } from 'src/core/data-and-assets/DataAssetsUtil';
import showPdf from 'src/core/pdf/pdfViewerAndroid';
import { createItem, createItemRoute } from 'src/core/util/ConfigItemFactory';
import { openUrl } from 'src/core/util/JsTools';
import * as Query from 'src/core/query/Query';
import { AD_BUNDLE_ATTRIBUTION_KEYS } from 'src/core/config-json/ConfigJsonManager';
import { isAndroid } from 'src/core/util/browser';
const LOG_PREF = '[homeConfig] ';
const {
  DEFAULT_PROFILE,
  VISITOR_PROFILE,
  EXHIBITOR_PROFILE,
  WEBAPP_EXH_PROFILE,
  WEBAPP_EVT_PROFILE,
  WEBAPP_SPK_PROFILE,
  WEBAPP_MAP
} = require('./profiles');

// NB: used for ChooseProfile page too !
export const getStyle = () => ({
  background: "url(".concat(getUrl('files/project/home/2000x2000px_AppLeni_BG_MOParis.png'), ") no-repeat"),
  backgroundSize: 'contain'
});
export const BASE_COLUMN_COUNT = {
  landscape: 3,
  portrait: 3
};
export const MAX_COLUMN_SIZE = {
  landscape: 190,
  // 2 columns mode => less width available
  portrait: 220
};
export const GENERAL_TILE_OPTIONS = {
  height: 1,
  // rowspan
  width: 1 // colspan
};

// const tileBgGradient = 'linear-gradient(rgba(17, 17, 17, 0.65), rgba(85, 85, 85, 0.65))'

function getPdf() {
  return createItem(
  // Style
  {
    container: {
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Actualites.svg'), ")")
      }
    }
  },
  // Label (i18n)
  'home.pdf2',
  // Action to perform on click
  actions => {
    const url = 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf';
    if (isAndroid()) {
      showPdf(url);
    } else if (openUrl(url, null, '_blank', true)) {
      actions.navigate(url);
    }
  }, GENERAL_TILE_OPTIONS);
}
function getPdf2() {
  return createItem(
  // Style
  {
    container: {
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Actualites.svg'), ")")
      }
    }
  },
  // Label (i18n)
  'home.pdf',
  // Action to perform on click
  actions => {
    const url = 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf';
    if (openUrl(url, null, isAndroid() ? '_system' : '_blank', !isAndroid())) {
      actions.navigate(url);
    }
  }, GENERAL_TILE_OPTIONS);
}
const getServiceIdByLumpMainId = (main_id, dataType) => {
  const items = Query.getTopCategories(dataType);
  let parentId;
  items && items.map(item => {
    if (item.lump.main_id === main_id) {
      parentId = item.id;
    }
  });
  return parentId;
};
const createItemAd = (homebutton, lang, backgroundPosition) => createItem(
// Style
{
  container: {
    style: {},
    className: 'home-btn-text'
  },
  icon: {
    style: {
      backgroundImage: "url(".concat(getUrl(homebutton["img_".concat(lang)]), ")")
    }
    // className: 'home-icon-plan',
  }
},
// Label (i18n)
homebutton["label_".concat(lang)],
// Action to perform on click
actions => {
  const url = homebutton["link_".concat(lang)];
  const {
    target
  } = homebutton;
  if (openUrl(url, null, target, true)) {
    actions.linkClicked(url);
  }
},
// Tile options
GENERAL_TILE_OPTIONS);
function getParticipantsTile() {
  if (config.NETWORKING.FEATURE_ENABLED !== true) {
    return null;
  }
  const isAllMode = config.NETWORKING.PARTICIPANTS_DATA_MODE === 'ALL';
  return createItemRoute(
  // Style
  {
    container: {
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/conferences.svg'), ")")
      }
    }
  },
  // Label (i18n)
  'home.miseEnRelation',
  // Page & props
  isAllMode ? LIST_PAGE_KEY : SEARCH_TAIGA_PAGE_KEY, isAllMode ? {
    inputs: [{
      dataType: DATA_TYPE_PARTICIPANTS
    }]
  } : {
    dataType: DATA_TYPE_PARTICIPANTS,
    isAdvanced: false
  },
  // Tile options
  GENERAL_TILE_OPTIONS);
}
const getEventsTile = () => createItemRoute(
// Style
{
  container: {
    className: 'home-btn-text'
  },
  icon: {
    style: {
      backgroundImage: "url(".concat(getUrl('files/project/home/Programme.svg'), ")")
    }
    // className: 'home-icon-programme',
  }
},
// Label (i18n)
'home.programme',
// Page & props
// LIST_GROUPS_PAGE_KEY,
// { inputs: [{ dataType: DATA_TYPE_EVENT_CATEGORIES }], hasGoToSynopticButton: false },
// { inputs: [{ dataType: DATA_TYPE_EVENTS }] },
LIST_GROUPS_PAGE_KEY, {
  locateAll: false,
  input: {
    dataType: DATA_TYPE_EVENTS
  }
},
// Tile options
GENERAL_TILE_OPTIONS);
const getEventsTile2 = () => createItemRoute(
// Style
{
  container: {
    className: 'home-btn-text'
  },
  icon: {
    style: {
      backgroundImage: "url(".concat(getUrl('files/project/home/Programme.svg'), ")")
    }
    // className: 'home-icon-programme',
  }
},
// Label (i18n)
'home.programmeSynoptic',
// Page & props
LIST_PAGE_KEY, {
  inputs: [{
    dataType: DATA_TYPE_EVENT_CATEGORIES
  }],
  hasGoToSynopticButton: true
},
// { inputs: [{ dataType: DATA_TYPE_EVENTS }] },
/* LIST_GROUPS_PAGE_KEY,
{ input: { dataType: DATA_TYPE_EVENTS } },
*/
// Tile options
GENERAL_TILE_OPTIONS);
const getServicesCategoriesTile = () => createItemRoute(
// Style
{
  container: {
    className: 'home-btn-text'
  },
  icon: {
    style: {
      backgroundImage: "url(".concat(getUrl('files/project/home/Infos_Pratiques.svg'), ")")
    }
    // className: 'home-icon-programme',
  }
},
// Label (i18n)
'home.practicalInfo2',
// Page & props
LIST_PAGE_KEY,
// { inputs: [{ dataType: DATA_TYPE_EVENT_CATEGORIES }], hasGoToSynopticButton: false },
{
  inputs: [{
    id: getServiceIdByLumpMainId('_BY_SERVICE_', DATA_TYPE_SERVICE_CATEGORIES),
    dataType: DATA_TYPE_SERVICE_CATEGORIES
  }]
},
// Tile options
GENERAL_TILE_OPTIONS);
const getServicesTile = () => createItemRoute(
// Style
{
  container: {
    className: 'home-btn-text'
  },
  icon: {
    style: {
      backgroundImage: "url(".concat(getUrl('files/project/home/Infos_Pratiques.svg'), ")")
    }
    // className: 'home-icon-programme',
  }
},
// Label (i18n)
'home.practicalInfo',
// Page & props
LIST_PAGE_KEY,
// { inputs: [{ dataType: DATA_TYPE_EVENT_CATEGORIES }], hasGoToSynopticButton: false },
{
  inputs: [{
    dataType: DATA_TYPE_SERVICES
  }]
},
// Tile options
GENERAL_TILE_OPTIONS);
function getNewsTile(lang) {
  /*  createItemRoute(
    // Style
    {
      container: {
        className: 'home-btn-text',
      },
      icon: {
        style: {
          backgroundImage: `url(${getUrl('files/project/home/Actualites.svg')})`,
        },
        // className: 'home-icon-programme',
      },
    },
     // Label (i18n)
    'home.news',
     // Page & props
    LIST_PAGE_KEY,
    // { inputs: [{ dataType: DATA_TYPE_EVENT_CATEGORIES }], hasGoToSynopticButton: false },
    {
      inputs: [
        {
          parentId: getServiceIdByLumpMainId('_BY_ACTUALITE_', DATA_TYPE_SERVICE_CATEGORIES),
          parentType: DATA_TYPE_SERVICE_CATEGORIES,
          dataType: DATA_TYPE_SERVICES,
        },
      ],
    },
     // Tile options
    GENERAL_TILE_OPTIONS
  ); */
  return createItem(
  // Style
  {
    container: {
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Actualites.svg'), ")")
      }
      // className: 'home-icon-programme',
    }
  },
  // Label (i18n)
  'home.news',
  // Action to perform on click
  actions => {
    if (openUrl(config.NEWS_LINK[lang], null, config.NEWS_LINK_TARGET, config.NEWS_LINK_OPENININAPPBROWSER)) {
      actions.linkClicked(config.NEWS_LINK[lang]);
    }
  },
  // Tile options
  GENERAL_TILE_OPTIONS
  /* {
        height:
          window.innerWidth < MAX_COLUMN_SIZE[orientation] * BASE_COLUMN_COUNT[orientation]
            ? 0.6
            : 0.9,
        width:
          window.innerWidth < MAX_COLUMN_SIZE[orientation] * BASE_COLUMN_COUNT[orientation] ? 2 : 1,
      } */);
}
const getExhibitorsTile = () => createItemRoute(
// Style
{
  container: {
    className: 'home-btn-text'
  },
  icon: {
    style: {
      backgroundImage: "url(".concat(getUrl('files/project/home/Exposants.svg'), ")")
    }
    // className: 'home-icon-programme',
  }
},
// Label (i18n)
'home.exhibitors',
// Page & props
LIST_PAGE_KEY,
// { inputs: [{ dataType: DATA_TYPE_EVENT_CATEGORIES }], hasGoToSynopticButton: false },
{
  hasFilter: true,
  inputs: [{
    dataType: DATA_TYPE_EXHIBITORS
  }]
},
// Tile options
GENERAL_TILE_OPTIONS);
const getMarketPlaceTile = () => createItemRoute(
// Style
{
  container: {
    className: 'home-btn-text'
  },
  icon: {
    style: {
      backgroundImage: "url(".concat(getUrl('files/project/home/Market_Place.svg'), ")")
    }
    // className: 'home-icon-programme',
  }
},
// Label (i18n)
'home.marketplace',
// Page & props
LIST_PAGE_KEY,
// { inputs: [{ dataType: DATA_TYPE_EVENT_CATEGORIES }], hasGoToSynopticButton: false },
{
  hasFilter: true,
  inputs: [{
    dataType: DATA_TYPE_NEWPRODUCTS
  }]
},
// Tile options
GENERAL_TILE_OPTIONS);
const getNewPapersTile = () => createItemRoute(
// Style
{
  container: {
    /* style: {
      backgroundImage: tileBgGradient,
      boxShadow: '1px 2px 1px rgba(0,0,0,.1)',
      margin: '0.5rem',
    }, */
    className: 'home-btn-text'
  },
  icon: {
    style: {
      backgroundImage: "url(".concat(getUrl('files/project/home/Meteo.svg'), ")")
    }
    // className: 'home-icon-programme',
  }
},
// Label (i18n)
'home.papers',
// Page & props
LIST_PAGE_KEY,
// { inputs: [{ dataType: DATA_TYPE_EVENT_CATEGORIES }], hasGoToSynopticButton: false },
{
  inputs: [{
    dataType: DATA_TYPE_PAPER_CATEGORIES
  }]
},
// Tile options
GENERAL_TILE_OPTIONS);
const getStoresTile = () => createItemRoute(
// Style
{
  container: {
    className: 'home-btn-text'
  },
  icon: {
    style: {
      backgroundImage: "url(".concat(getUrl('files/project/home/participants.svg'), ")")
    }
    // className: 'home-icon-programme',
  }
},
// Label (i18n)
'home.stores',
// Page & props
LIST_PAGE_KEY,
// { inputs: [{ dataType: DATA_TYPE_EVENT_CATEGORIES }], hasGoToSynopticButton: false },
{
  inputs: [{
    dataType: DATA_TYPE_STORE_CATEGORIES
  }]
},
// Tile options
GENERAL_TILE_OPTIONS);
const getGoldenSummitTile = () => createItemRoute(
// Style
{
  container: {
    className: 'home-btn-text'
  },
  icon: {
    style: {
      backgroundImage: "url(".concat(getUrl('files/project/home/Sommet_Or.svg'), ")")
    }
    // className: 'home-icon-programme',
  }
},
// Label (i18n)
'home.goldensummit',
// Page & props
LIST_PAGE_KEY,
// { inputs: [{ dataType: DATA_TYPE_EVENT_CATEGORIES }], hasGoToSynopticButton: false },
{
  inputs: [{
    dataType: DATA_TYPE_GOLDENSUMMIT_CATEGORIES
  }]
},
// Tile options
GENERAL_TILE_OPTIONS);
const getContestsTile = () => createItemRoute(
// Style
{
  container: {
    className: 'home-btn-text'
  },
  icon: {
    style: {
      backgroundImage: "url(".concat(getUrl('files/project/home/Concours_Animaux.svg'), ")")
    }
    // className: 'home-icon-programme',
  }
},
// Label (i18n)
'home.contests',
// Page & props
LIST_GROUPS_PAGE_KEY,
// { inputs: [{ dataType: DATA_TYPE_EVENT_CATEGORIES }], hasGoToSynopticButton: false },
{
  hasFilter: true,
  input: {
    dataType: DATA_TYPE_CONTESTS
  }
},
// Tile options
GENERAL_TILE_OPTIONS);
const getJobOffersTile = () => createItemRoute(
// Style
{
  container: {
    className: 'home-btn-text'
  },
  icon: {
    style: {
      backgroundImage: "url(".concat(getUrl('files/project/home/Offres_Emploi.svg'), ")")
    }
    // className: 'home-icon-programme',
  }
},
// Label (i18n)
'home.jobOffers',
// Page & props
LIST_PAGE_KEY,
// { inputs: [{ dataType: DATA_TYPE_EVENT_CATEGORIES }], hasGoToSynopticButton: false },
{
  hasFilter: true,
  inputs: [{
    dataType: DATA_TYPE_JOBOFFERS
  }]
},
// Tile options
GENERAL_TILE_OPTIONS);
const getSpeakersTile = () => createItemRoute(
// Style
{
  container: {
    className: 'home-btn-text'
  },
  icon: {
    style: {
      backgroundImage: "url(".concat(getUrl('files/project/home/intervenants.svg'), ")")
    }
    // className: 'home-icon-intervenants',
  }
},
// Label (i18n)
'home.speakers',
// Page & props
LIST_PAGE_KEY, {
  contextualTitle: '',
  inputs: [{
    dataType: DATA_TYPE_SPEAKERS
  }]
},
// Tile options
GENERAL_TILE_OPTIONS);
const getSocialMediaTile = () => createItemRoute(
// Style
{
  container: {
    className: 'home-btn-text'
  },
  icon: {
    style: {
      backgroundImage: "url(".concat(getUrl('files/project/home/Reseaux_Sociaux.svg'), ")")
    }
  }
},
// Label (i18n)
'home.medias',
// Page & props
MEDIAS_PAGE_KEY, {
  /*   controlMediaPageContent: {
    // hideTabMediaSocial: true,
    mediaTabKey: 'twitter',
  }, */
},
// Tile options
GENERAL_TILE_OPTIONS);
const getHelpersMediaTile = () => createItemRoute(
// Style
{
  container: {
    className: 'home-btn-text'
  },
  icon: {
    style: {
      backgroundImage: "url(".concat(getUrl('files/project/home/Reseaux_Sociaux.svg'), ")")
    }
  }
},
// Label (i18n)
'home.helpers',
// Page & props
HELPERS_PAGE_KEY, null,
// Tile options
GENERAL_TILE_OPTIONS);
function getEbadgeTile(lang) {
  return createItem(
  // Style
  {
    container: {
      className: 'home-btn-text'
    },
    icon: {
      style: {
        maskImage: "url(".concat(getUrl('icons-svg/badge.svg', false), ")"),
        WebkitMaskImage: "url(".concat(getUrl('icons-svg/badge.svg', false), ")"),
        WebkitMaskPosition: 'center',
        WebkitMaskRepeat: 'no-repeat',
        WebkitMaskSize: '100%',
        backgroundColor: '#F39200',
        maskPosition: 'center',
        maskRepeat: 'no-repeat',
        maskSize: '100%',
        height: '1em',
        width: '2em',
        fontSize: '2em'
      }
    }
  },
  // Label (i18n)
  'home.ebadge',
  // Action to perform on click
  () => {
    if (openUrl(config.BADGE_LINK[lang], null, config.BADGE_LINK_TARGET, config.BADGE_LINK_OPENININAPPBROWSER)) {
      actions.linkClicked(config.BADGE_LINK[lang]);
    }
  },
  // Tile options
  GENERAL_TILE_OPTIONS
  /* {
      height:
        window.innerWidth < MAX_COLUMN_SIZE[orientation] * BASE_COLUMN_COUNT[orientation]
          ? 0.6
          : 0.9,
      width:
        window.innerWidth < MAX_COLUMN_SIZE[orientation] * BASE_COLUMN_COUNT[orientation] ? 2 : 1,
    } */);
}
const getMobigeoTile = () => createItemRoute(
// Style
{
  container: {
    className: 'home-btn-text'
  },
  icon: {
    style: {
      backgroundImage: "url(".concat(getUrl('files/project/home/Plan.svg'), ")")
    }
    // className: 'home-icon-plan',
  }
},
// Label (i18n)
'home.mobigeo',
// Page & props
MOBIGEO_PAGE_KEY, null,
// Tile options

GENERAL_TILE_OPTIONS);
const getGoogleMapTile = () => createItemRoute(
// Style
{
  container: {
    className: 'home-btn-text'
  },
  icon: {
    style: {
      backgroundImage: "url(".concat(getUrl('files/project/home/Plan.svg'), ")")
    }
    // className: 'home-icon-plan',
  }
},
// Label (i18n)
'home.googleMap',
// Page & props
GOOGLE_MAP_PAGE_KEY, null,
// Tile options

GENERAL_TILE_OPTIONS);
const getNotifications = () => createItemRoute(
// Style
{
  container: {
    className: 'home-btn-text'
  },
  icon: {
    style: {
      backgroundImage: "url(".concat(getUrl('files/project/home/Notifications.svg'), ")")
    }
    // className: 'home-icon-plan',
  }
},
// Label (i18n)
'home.notifications',
// Page & props
INBOX_PAGE_KEY, null,
// Tile options
GENERAL_TILE_OPTIONS);
const getVideos = () => createItemRoute(
// Style
{
  container: {
    className: 'home-btn-text'
  },
  icon: {
    style: {
      backgroundImage: "url(".concat(getUrl('files/project/home/Youtube.svg'), ")")
    }
    // className: 'home-icon-plan',
  }
},
// Label (i18n)
'home.videos',
// Page & props
MEDIAS_PAGE_KEY, {
  controlMediaPageContent: {
    // hideTabMediaSocial: true,
    mediaTabKey: 'youtube' // to match index of social Media
  }
},
// Tile options
GENERAL_TILE_OPTIONS);
const getDefault = (lang, adConfig, orientation, isLoggedIn) => {
  const hasAdButtons = adConfig && adConfig.homebuttons !== null && typeof adConfig.homebuttons === 'object';
  return [{
    component: AdSwap,
    props: {
      adBundleAttributionKey: AD_BUNDLE_ATTRIBUTION_KEYS.HOME_HEADER
    }
  }, {
    component: HomeToolbar
  }, {
    component: HomeTiles,
    props: {
      baseColumnCount: BASE_COLUMN_COUNT[orientation],
      maxColumnSize: MAX_COLUMN_SIZE[orientation],
      homeContent_Padding: 3,
      tiles: [
      // pdf externe
      isAndroid() && getPdf(),
      // pdf interne
      getPdf2(),
      // Exposants
      getExhibitorsTile(), getNewPapersTile(),
      // MarketPlace
      getMarketPlaceTile(),
      // Programme
      getEventsTile(), getEventsTile2(),
      // Speakers
      getSpeakersTile(),
      // PLAN
      getMobigeoTile(), getGoogleMapTile(), getStoresTile(),
      // Sommet d’or
      getGoldenSummitTile(),
      // Concours Animaux
      getContestsTile(),
      // Offres d’emploi
      // getJobOffersTile(),

      // Mise en relation
      getParticipantsTile(),
      // Actualites
      getNewsTile(lang),
      // INFOS PRATIQUES
      getServicesTile(), getServicesCategoriesTile(),
      // Badges
      getEbadgeTile(lang),
      // Social Medias
      getSocialMediaTile(),
      // helpers
      config.HELPERS && config.HELPERS.FEATURE_ENABLED && getHelpersMediaTile(),
      // Videos
      // getVideos(),
      // INTERACTIVITE ATELIERS
      hasAdButtons && adConfig.homebuttons.homebutton1 ? createItemAd(adConfig.homebuttons.homebutton1, lang) : null]
    }
  }, {
    component: AdSwap,
    props: {
      adBundleAttributionKey: AD_BUNDLE_ATTRIBUTION_KEYS.HOME
    }
  }, {
    component: MobilespotBar
  }, {
    component: IphonexFlexMargin
  }];
};
const getVisitor = (lang, adConfig, orientation, isLoggedIn) => {
  const hasAdButtons = adConfig && adConfig.homebuttons !== null && typeof adConfig.homebuttons === 'object';
  return [{
    component: AdSwap,
    props: {
      adBundleAttributionKey: AD_BUNDLE_ATTRIBUTION_KEYS.HOME_HEADER
    }
  }, {
    component: HomeToolbar,
    props: {
      // Black list of buttons just to avoid side effect on older projects
      disabledButtons: ['inbox']
    }
  }, {
    component: HomeTiles,
    props: {
      baseColumnCount: BASE_COLUMN_COUNT[orientation],
      maxColumnSize: MAX_COLUMN_SIZE[orientation],
      tiles: [
      // Exposants
      // getExhibitorsTile(),

      getNewPapersTile(),
      // MarketPlace
      // getMarketPlaceTile(),

      // Programme
      getEventsTile(), getEventsTile2(),
      // Speakers
      getSpeakersTile(),
      // PLAN
      getMobigeoTile(),
      // Sommet d’or
      // getGoldenSummitTile(),

      // Concours Animaux
      // getContestsTile(),

      // Offres d’emploi
      // getJobOffersTile(),

      // Mise en relation
      getParticipantsTile(),
      // Actualites
      getNewsTile(lang),
      // INFOS PRATIQUES
      getServicesTile(), getServicesCategoriesTile(),
      // Badges
      // getEbadgeTile(lang),

      // Social Medias
      getSocialMediaTile(),
      // helpers
      config.HELPERS && config.HELPERS.FEATURE_ENABLED && getHelpersMediaTile(),
      // Videos
      // getVideos(),
      // INTERACTIVITE ATELIERS
      hasAdButtons && adConfig.homebuttons.homebutton1 ? createItemAd(adConfig.homebuttons.homebutton1, lang) : null]
    }
  }, {
    component: AdSwap,
    props: {
      adBundleAttributionKey: AD_BUNDLE_ATTRIBUTION_KEYS.HOME
    }
  }, {
    component: MobilespotBar
  }, {
    component: IphonexFlexMargin
  }];
};
const getExhibitor = (lang, adConfig, orientation, isLoggedIn) => {
  const hasAdButtons = adConfig && adConfig.homebuttons !== null && typeof adConfig.homebuttons === 'object';
  return [{
    component: AdSwap,
    props: {
      adBundleAttributionKey: AD_BUNDLE_ATTRIBUTION_KEYS.HOME_HEADER
    }
  }, {
    component: HomeToolbar,
    props: {
      // Black list of buttons just to avoid side effect on older projects
      disabledButtons: ['inbox']
    }
  }, {
    component: HomeTiles,
    props: {
      baseColumnCount: BASE_COLUMN_COUNT[orientation],
      maxColumnSize: MAX_COLUMN_SIZE[orientation],
      tiles: [
      // Exposants
      getExhibitorsTile(), getNewPapersTile(),
      // MarketPlace
      // getMarketPlaceTile(),

      // Programme
      getEventsTile(), getEventsTile2(),
      // Speakers
      getSpeakersTile(),
      // PLAN
      getMobigeoTile(),
      // Sommet d’or
      // getGoldenSummitTile(),

      // Concours Animaux
      // getContestsTile(),

      // Offres d’emploi
      // getJobOffersTile(),

      // Mise en relation
      getParticipantsTile(),
      // Actualites
      getNewsTile(lang),
      // INFOS PRATIQUES
      getServicesTile(), getServicesCategoriesTile(),
      // Badges
      // getEbadgeTile(lang),

      // Social Medias
      getSocialMediaTile(),
      // helpers
      config.HELPERS && config.HELPERS.FEATURE_ENABLED && getHelpersMediaTile(),
      // Videos
      // getVideos(),
      // INTERACTIVITE ATELIERS
      hasAdButtons && adConfig.homebuttons.homebutton1 ? createItemAd(adConfig.homebuttons.homebutton1, lang) : null]
    }
  }, {
    component: AdSwap,
    props: {
      adBundleAttributionKey: AD_BUNDLE_ATTRIBUTION_KEYS.HOME
    }
  }, {
    component: MobilespotBar
  }, {
    component: IphonexFlexMargin
  }];
};
const getWebapp_exh_profile = (lang, adConfig, orientation, isLoggedIn) => {
  const hasAdButtons = adConfig && adConfig.homebuttons !== null && typeof adConfig.homebuttons === 'object';
  return [{
    component: AdSwap,
    props: {
      adBundleAttributionKey: AD_BUNDLE_ATTRIBUTION_KEYS.HOME_HEADER
    }
  }, {
    component: HomeToolbar,
    props: {
      // Black list of buttons just to avoid side effect on older projects
      disabledButtons: ['inbox']
    }
  }, {
    component: HomeTiles,
    props: {
      baseColumnCount: BASE_COLUMN_COUNT[orientation],
      maxColumnSize: MAX_COLUMN_SIZE[orientation],
      tiles: [
      // Exposants
      // getExhibitorsTile(),

      getNewPapersTile(),
      // MarketPlace
      // getMarketPlaceTile(),

      // Programme
      getEventsTile(), getEventsTile2(),
      // Speakers
      getSpeakersTile(),
      // PLAN
      getMobigeoTile(),
      // Sommet d’or
      // getGoldenSummitTile(),

      // Concours Animaux
      // getContestsTile(),

      // Offres d’emploi
      // getJobOffersTile(),

      // Mise en relation
      getParticipantsTile(),
      // Actualites
      getNewsTile(lang),
      // INFOS PRATIQUES
      getServicesTile(), getServicesCategoriesTile(),
      // Badges
      // getEbadgeTile(lang),

      // Social Medias
      getSocialMediaTile(),
      // helpers
      config.HELPERS && config.HELPERS.FEATURE_ENABLED && getHelpersMediaTile(),
      // Videos
      // getVideos(),
      // INTERACTIVITE ATELIERS
      hasAdButtons && adConfig.homebuttons.homebutton1 ? createItemAd(adConfig.homebuttons.homebutton1, lang) : null]
    }
  }, {
    component: AdSwap,
    props: {
      adBundleAttributionKey: AD_BUNDLE_ATTRIBUTION_KEYS.HOME
    }
  }, {
    component: MobilespotBar
  }, {
    component: IphonexFlexMargin
  }];
};
const getWebapp_spk_profile = (lang, adConfig, orientation, isLoggedIn) => {
  const hasAdButtons = adConfig && adConfig.homebuttons !== null && typeof adConfig.homebuttons === 'object';
  return [{
    component: AdSwap,
    props: {
      adBundleAttributionKey: AD_BUNDLE_ATTRIBUTION_KEYS.HOME_HEADER
    }
  }, {
    component: HomeToolbar,
    props: {
      // Black list of buttons just to avoid side effect on older projects
      disabledButtons: ['inbox']
    }
  }, {
    component: HomeTiles,
    props: {
      baseColumnCount: BASE_COLUMN_COUNT[orientation],
      maxColumnSize: MAX_COLUMN_SIZE[orientation],
      tiles: [
      // Exposants
      // getExhibitorsTile(),

      getNewPapersTile(),
      // MarketPlace
      // getMarketPlaceTile(),

      // Programme
      getEventsTile(), getEventsTile2(),
      // Speakers
      getSpeakersTile(),
      // PLAN
      getMobigeoTile(),
      // Sommet d’or
      // getGoldenSummitTile(),

      // Concours Animaux
      // getContestsTile(),

      // Offres d’emploi
      // getJobOffersTile(),

      // Mise en relation
      getParticipantsTile(),
      // Actualites
      getNewsTile(lang),
      // INFOS PRATIQUES
      getServicesTile(), getServicesCategoriesTile(),
      // Badges
      // getEbadgeTile(lang),

      // Social Medias
      getSocialMediaTile(),
      // helpers
      config.HELPERS && config.HELPERS.FEATURE_ENABLED && getHelpersMediaTile(),
      // Videos
      // getVideos(),
      // INTERACTIVITE ATELIERS
      hasAdButtons && adConfig.homebuttons.homebutton1 ? createItemAd(adConfig.homebuttons.homebutton1, lang) : null]
    }
  }, {
    component: AdSwap,
    props: {
      adBundleAttributionKey: AD_BUNDLE_ATTRIBUTION_KEYS.HOME
    }
  }, {
    component: MobilespotBar
  }, {
    component: IphonexFlexMargin
  }];
};
const getWebapp_evt_profile = (lang, adConfig, orientation, isLoggedIn) => {
  const hasAdButtons = adConfig && adConfig.homebuttons !== null && typeof adConfig.homebuttons === 'object';
  return [{
    component: AdSwap,
    props: {
      adBundleAttributionKey: AD_BUNDLE_ATTRIBUTION_KEYS.HOME_HEADER
    }
  }, {
    component: HomeToolbar,
    props: {
      // Black list of buttons just to avoid side effect on older projects
      disabledButtons: ['inbox']
    }
  }, {
    component: HomeTiles,
    props: {
      baseColumnCount: BASE_COLUMN_COUNT[orientation],
      maxColumnSize: MAX_COLUMN_SIZE[orientation],
      tiles: [
      // Exposants
      // getExhibitorsTile(),

      getNewPapersTile(),
      // MarketPlace
      // getMarketPlaceTile(),

      // Programme
      getEventsTile(), getEventsTile2(),
      // Speakers
      getSpeakersTile(),
      // PLAN
      getMobigeoTile(),
      // Sommet d’or
      // getGoldenSummitTile(),

      // Concours Animaux
      // getContestsTile(),

      // Offres d’emploi
      // getJobOffersTile(),

      // Mise en relation
      getParticipantsTile(),
      // Actualites
      getNewsTile(lang),
      // INFOS PRATIQUES
      getServicesTile(), getServicesCategoriesTile(),
      // Badges
      // getEbadgeTile(lang),

      // Social Medias
      getSocialMediaTile(),
      // helpers
      config.HELPERS && config.HELPERS.FEATURE_ENABLED && getHelpersMediaTile(),
      // Videos
      // getVideos(),
      // INTERACTIVITE ATELIERS
      hasAdButtons && adConfig.homebuttons.homebutton1 ? createItemAd(adConfig.homebuttons.homebutton1, lang) : null]
    }
  }, {
    component: AdSwap,
    props: {
      adBundleAttributionKey: AD_BUNDLE_ATTRIBUTION_KEYS.HOME
    }
  }, {
    component: MobilespotBar
  }, {
    component: IphonexFlexMargin
  }];
};

/**
 * Return home configuration
 * @param  {string} profile
 * @param  {string} lang
 * @param  {object} adConfig
 * @param  {string} orientation
 * @param  {boolean} isLoggedIn
 * @return {object}
 */
export function get(profile, lang, adConfig, orientation, isLoggedIn) {
  switch (profile) {
    // PRO
    case DEFAULT_PROFILE:
      return getDefault(lang, adConfig, orientation, isLoggedIn);
    // GRAND PUBLIC
    case EXHIBITOR_PROFILE:
      return getExhibitor(lang, adConfig, orientation, isLoggedIn);
    case VISITOR_PROFILE:
      return getVisitor(lang, adConfig, orientation, isLoggedIn);
    case WEBAPP_EVT_PROFILE:
      return getWebapp_evt_profile(lang, adConfig, orientation, isLoggedIn);
    case WEBAPP_EXH_PROFILE:
      return getWebapp_exh_profile(lang, adConfig, orientation, isLoggedIn);
    case WEBAPP_SPK_PROFILE:
      return getWebapp_spk_profile(lang, adConfig, orientation, isLoggedIn);
    case WEBAPP_MAP:
      break;
    default:
      if (!profile) {
        console.warn("".concat(LOG_PREF, "No profile set yet"));
      } else {
        console.error("".concat(LOG_PREF, "Unsupported profile: ").concat(profile));
      }
  }
}
import React from 'react';
import PropTypes from 'prop-types';

import GenericIcon from 'src/components/generic-icon/GenericIcon';

function DetailCountry({ country }) {
  return !country ? null : (
    <div>
      <div className="free-row">
        <div className="prop-img">
          <div className="prop-left">
            <div className="fiche-contact-icon">
              <GenericIcon
                src="icons-svg/fiche/globe-solid.svg"
                className="globe-icon fiche-icon"
              />
            </div>
          </div>
          <div className="prop-right">
            <span>{country}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

DetailCountry.propTypes = {
  country: PropTypes.string,
};

export default DetailCountry;

import { MEETING_PAGE_KEY } from 'src/pages/pagesKeys';

import GenericItemPage, { DOM_ID } from 'src/pages/generic-item-page/GenericItemPage';

import { isSessionValid } from 'src/core/login/LoginService';
import MeetingContactsContent from './MeetingContactsContent';

export default {
  key: MEETING_PAGE_KEY,
  path: '/meetingContacts',
  elId: DOM_ID,
  className: 'meetingContacts-page',
  component: GenericItemPage,
  childComponent: MeetingContactsContent,
  isGranted: () => isSessionValid(),
};

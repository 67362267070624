import React from 'react';
import PropTypes from 'prop-types';
import { TwitterTweetEmbed } from 'react-twitter-embed';
import { isAndroid } from 'src/core/util/browser';

class Tweet extends React.PureComponent {
  render() {
    const { id, socialId } = this.props;

    const tweetUrl = `https://twitter.com/${socialId}/status/${id}`;

    if (global.isCordovaContext && isAndroid()) {
      return (
        <div className="media-twitter-post" onClick={() => window.open(tweetUrl, '_system')}>
          <TwitterTweetEmbed tweetId={id} />
        </div>
      );
    }

    return (
      <div className="media-twitter-post">
        <TwitterTweetEmbed tweetId={id} />
      </div>
    );
  }
}

Tweet.propTypes = {
  id: PropTypes.string.isRequired,
};

export default Tweet;

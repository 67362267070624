import {
  CONFIG_JSON_LOADED,
  POLL_CONFIG_LOADED,
  DATA_ASSETS_UPDATED,
  FETCH_NOTES,
  HAS_NAVIGATED,
  LANG_CHANGED,
  PROFILE_CHANGED,
  TOGGLE_LOCATION_STATUS,
  TOGGLE_MENU,
  UPDATE_PAGE_STATE,
  NOTE_DELETED,
  NOTE_SAVED,
} from 'src/store/actionTypes';

import {
  configJsonLoaded,
  pollConfigLoaded,
  getPageCommonDefaultState,
  langChanged,
  togglePageAfterNavigation,
  profileChanged,
  toggleLocationStatus,
  toggleMenu,
  updateObject,
  updatePageState,
} from 'src/store/reducers/commons';

import STATUS from 'src/store/fetchStatuses';

import { NOTES_PAGE_KEY } from 'src/pages/pagesKeys';

function getDefaultState() {
  return { ...getPageCommonDefaultState(NOTES_PAGE_KEY) };
}

// Set `shouldFetch`: true so the page will refresh its content
const __setShouldFetchTrue = (state) => updateObject(state, { shouldFetch: true });

const _dataUpdated = (state, action) => __setShouldFetchTrue(state);

/**
 * Initial action for this page
 * @param  {object} state
 * @param  {object} action
 * @return {object}
 */
const _fetchNotes = (state, action) =>
  updateObject(state, {
    notes: action.notes,
    data: action.data,
    isPending: action.status === STATUS.PENDING,
    shouldFetch: false,
  });

const _noteDeleted = (state, action) =>
  updateObject(state, {
    notes: action.notes,
    notesData: action.notes.data,
    shouldFetch: true,
  });

const _noteSaved = (state, action) =>
  updateObject(state, {
    note: action.note,
    notes: action.notes,
    shouldFetch: true,
  });

export default (state = getDefaultState(), action) => {
  switch (action.type) {
    case CONFIG_JSON_LOADED:
      return configJsonLoaded(state);

    case DATA_ASSETS_UPDATED:
      return _dataUpdated(state, action);

    case FETCH_NOTES:
      return _fetchNotes(state, action);

    case NOTE_DELETED:
      return _noteDeleted(state, action);

    case NOTE_SAVED:
      return _noteSaved(state, action);

    case LANG_CHANGED:
      return langChanged(state, action);

    case POLL_CONFIG_LOADED:
      return pollConfigLoaded(state, action);

    case PROFILE_CHANGED:
      return profileChanged(state, action, NOTES_PAGE_KEY);

    case TOGGLE_LOCATION_STATUS:
      return toggleLocationStatus(state, action);

    case TOGGLE_MENU:
      return toggleMenu(state, action, NOTES_PAGE_KEY);

    case UPDATE_PAGE_STATE:
      return updatePageState(state, action, NOTES_PAGE_KEY);

    case HAS_NAVIGATED:
      return togglePageAfterNavigation(state, NOTES_PAGE_KEY, action.pageKey);

    default:
      return state;
  }
};

import React from 'react';
import PropTypes from 'prop-types';

import { getBadgeFields } from 'src/core/klipso-leads/KlipsoLeadsData';

import './ScanResult.scss';

class ScanResult extends React.Component {
  renderResult() {
    const { result } = this.props;

    if (typeof result === 'string') {
      return result;
    }
    if (result !== null && typeof result === 'object') {
      const badgeFields = getBadgeFields();

      return (
        <div>
          <div>
            <span className="klc-result-field">Code:</span>
            <span>{result.code}</span>
          </div>

          {!badgeFields ? (
            <p style={{ color: 'red' }}>Missing badge fields!</p>
          ) : (
            badgeFields.map((badgeField) => (
              <div>
                <span className="klc-result-field">{badgeField.Label}:</span>
                <span>{result[badgeField.Code]}</span>
              </div>
            ))
          )}
        </div>
      );
    }
  }

  render() {
    return (
      <>
        {this.props.result && (
          <div className="kl-scan-result-block">Result:{this.renderResult()}</div>
        )}
        {this.props.resultFormat && (
          <div className="kl-scan-result-block">Format:{this.props.resultFormat}</div>
        )}
      </>
    );
  }
}

ScanResult.propTypes = {
  result: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  resultFormat: PropTypes.string,
};

export default ScanResult;

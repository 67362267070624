import React from 'react';
import PropTypes from 'prop-types';

import './StatusBlock.scss';

const getContactsCountLabel = (contactsCount, labels) =>
  `${contactsCount} ${
    contactsCount > 1 ? labels.data.kleads_contacts.plural : labels.data.kleads_contacts.singular
  }`;

function StatusBlock({ eventName, contactsCount, nonSyncContactsCount, labels }) {
  return (
    <div id="kl-status-block">
      <div className="kl-sb-eventname">{eventName || ''}</div>

      {/* below section is optional, see ExtendedMenuScreen */}
      {labels && typeof contactsCount === 'number' && typeof nonSyncContactsCount === 'number' && (
        <div className="kl-sb-contacts-row">
          <span className="kl-sb-count">{getContactsCountLabel(contactsCount, labels)}</span>

          <SyncContacts
            contactsCount={contactsCount}
            nonSyncContactsCount={nonSyncContactsCount}
            labels={labels}
          />
        </div>
      )}
    </div>
  );
}

StatusBlock.propTypes = {
  eventName: PropTypes.string.isRequired,
  contactsCount: PropTypes.number,
  nonSyncContactsCount: PropTypes.number,
  labels: PropTypes.object,
};

export default StatusBlock;

function SyncContacts({ contactsCount, nonSyncContactsCount, labels }) {
  if (contactsCount === 0) {
    return null;
  }

  if (nonSyncContactsCount < 1) {
    return (
      <>
        <CountSeparator sync />
        <span className="kl-sb-all-sync">{labels.klipsoLeads.allContactsSync}</span>
      </>
    );
  }

  let label;
  if (nonSyncContactsCount < 2) {
    label = nonSyncContactsCount + labels.klipsoLeads.contactsNotSync.singular;
  } else {
    label = nonSyncContactsCount + labels.klipsoLeads.contactsNotSync.plural;
  }

  return (
    <>
      <CountSeparator sync={false} />
      <span className="kl-sb-nonsync-count">{label}</span>
    </>
  );
}

function CountSeparator({ sync }) {
  return (
    <span className={`kl-sb-count-separator ${sync ? 'kl-sb-cs-sync' : 'kl-sb-cs-notsync'}`} />
  );
}

import React from 'react';
import PropTypes from 'prop-types';

import GenericIcon from 'src/components/generic-icon/GenericIcon';

function RoleRow(props) {
  return !props.role ? null : (
    <div className="free-row">
      <div className="prop-img">
        <div className="prop-left">
          <GenericIcon className="suitcase-icon icon-color2" />
        </div>
        <div
          className="prop-right"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <a className="link colored-link">{props.role}</a>
        </div>
      </div>
    </div>
  );
}

RoleRow.propTypes = {
  role: PropTypes.string,
};

export default RoleRow;

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import config from 'app-customs/config/config';

import CTAButton from 'src/components/cta-button/CTAButton';
import NotificationLevels from 'src/components-standalone/notifications/NotificationLevels';

import GenericIcon from 'src/components/generic-icon/GenericIcon';
import renderSection from './renderSection';
import renderAdvancedSearchFormWithResults from './renderAdvancedSearchFormWithResults';

import './SearchForm.scss';

const LOG_PREF = '[SearchForm] ';

const DEFAULT_VISIBLE_BUTTON_INDEX = 0;

const _config = config.SEARCH_TAIGA;

/**
 * Parse a configuration section to detect fields
 * @param  {object} sections
 * @return {object} fields
 */
function getFields(sections) {
  const parsedFields = {};

  sections.forEach((section) => {
    // special case: Buttons
    (section.buttons || []).forEach((button) => {
      switch (button.type) {
        case 'TOGGLE':
          button.content.forEach((btnConf) => {
            const innerFieldName = Object.keys(btnConf.field)[0];
            parsedFields[innerFieldName] = btnConf.field[innerFieldName];
          });
          break;

        default:
          console.error(`${LOG_PREF}Button type '${button.type}' is not managed yet`);
      }
    });

    // Regular fields
    const fieldNames = Object.keys(section.fields || {});
    fieldNames.forEach((fieldName) => {
      parsedFields[fieldName] = section.fields[fieldName];
    });
  });

  return parsedFields;
}

/**
 * Parse a configuration section to detect buttons of type TOGGLE
 * @param  {object} sections
 * @return {array} toggleButtons
 */
function getToggleButtons(sections) {
  const toggleButtons = [];
  sections.forEach((section) => {
    if (section.buttons && Array.isArray(section.buttons)) {
      section.buttons.forEach((buttonConfig) => {
        switch (buttonConfig.type) {
          case 'TOGGLE':
            if (!buttonConfig.key) {
              console.error(
                `${LOG_PREF}Toggle button configuration must have a unique 'key' property`
              );
            } else {
              toggleButtons.push(buttonConfig);
            }
            break;

          default: // noop
        }
      });
    }
  });
  return toggleButtons;
}

function getCurrentFormValue(fields, fieldsHooks, fieldName) {
  const { currentFormValue } = fieldsHooks[fieldName];

  const fieldConfig = fields[fieldName];

  switch (fieldConfig.type) {
    case 'text-modal':
    case 'text':
      return !currentFormValue ? null : currentFormValue;
    case 'checkbox-modal':
    case 'checkbox':
      if (currentFormValue.length === 0) {
        // checkboxes checked: none
        return null;
      }
      return currentFormValue;

    default:
      console.error(`${LOG_PREF}Field type '${fieldConfig.type}' is not managed yet`);
  }
}

function SearchForm({
  isAdvanced = false,
  toggleSearchMode,
  search,
  isSearchOngoing,
  hasResults,
  actions,
  labels,
}) {
  const simpleSearchFields = getFields(_config.SIMPLE_SECTIONS);
  const advancedSearchFields = getFields(_config.ADVANCED_SECTIONS);

  // Parsed fields for simple + advanced
  const fields = {
    ...simpleSearchFields,
    ...advancedSearchFields,
  };
  // console.log(LOG_PREF+'parsed fields:', fields);

  // Initiate hooks
  const fieldsHooks = {};
  Object.keys(fields).forEach((fieldName) => {
    let defaultValue;
    const fieldType = fields[fieldName].type;
    switch (fieldType) {
      case 'text-modal':
      case 'text':
        defaultValue = '';
        break;
      case 'checkbox-modal':
      case 'checkbox':
        if (fields[fieldName].multiple) {
          defaultValue = [];
        } else {
          defaultValue = '';
        }
        break;

      default:
        console.error(`${LOG_PREF}TODO: manage field type '${fieldType}'`);
    }

    const [currentFormValue, setValue] = useState(defaultValue);
    fieldsHooks[fieldName] = {
      currentFormValue,
      setValue,
    };
  });
  // console.log(LOG_PREF+'hooks initialized:', fieldsHooks);

  function doesAVisibleFieldIncludesSearchButton() {
    const fields = !isAdvanced ? simpleSearchFields : advancedSearchFields;
    const fieldNames = Object.keys(fields);
    for (let i = 0; i < fieldNames.length; i++) {
      const fieldName = fieldNames[i];
      if (fieldsVisiblity[fieldName] === true) {
        if (fields[fieldName].hasSearchButton) {
          return true;
        }
      }
    }
    return false;
  }

  // Initiate toggle buttons visibility hooks
  const toggleButtonsConfigs = []
    .concat(getToggleButtons(_config.SIMPLE_SECTIONS))
    .concat(getToggleButtons(_config.ADVANCED_SECTIONS));

  const toggleButtonsVisiblityHooks = {};
  toggleButtonsConfigs.forEach((tbc) => {
    const visibleIndex =
      typeof tbc.defaultVisibleButtonIndex === 'number'
        ? tbc.defaultVisibleButtonIndex
        : DEFAULT_VISIBLE_BUTTON_INDEX;

    toggleButtonsVisiblityHooks[tbc.key] = useState(visibleIndex);
  });

  // Manage fields visibility to know what data
  // must be attached to webservice call
  let fieldsVisiblity = {};
  function setFieldVisiblity(fieldName, value) {
    fieldsVisiblity[fieldName] = value;
  }
  function setAllFieldsNotVisible() {
    Object.keys(fields).forEach((fieldName) => {
      setFieldVisiblity(fieldName, false);
    });
  }
  setAllFieldsNotVisible();

  function performSearch() {
    const searchFields = {};

    Object.keys(fieldsVisiblity).forEach((fieldName) => {
      if (fieldsVisiblity[fieldName] === true) {
        const value = getCurrentFormValue(fields, fieldsHooks, fieldName);
        if (value !== null) {
          searchFields[fieldName] = value;
        }
      }
    });

    console.log(`${LOG_PREF}search fields: `, searchFields);

    if (Object.keys(searchFields).length === 0) {
      actions.showNotification({
        message: labels.searchTaiga.emptyParameters,
        level: NotificationLevels.WARNING,
      });
      return;
    }

    // perform search
    search(searchFields);
  }

  function goToSimple() {
    toggleSearchMode({
      isAdvanced: false,
    });
  }

  function goToAdvanced() {
    toggleSearchMode({
      isAdvanced: true,
    });
  }

  // Results list displayed
  if (hasResults && isAdvanced) {
    return renderAdvancedSearchFormWithResults({
      labels,
      actions,
      goToSimple,
    });
  }

  // render
  const sections = !isAdvanced ? _config.SIMPLE_SECTIONS : _config.ADVANCED_SECTIONS;
  return (
    <div
      id="search-form"
      className={`content-font ${isAdvanced ? 'search-form-advanced' : 'search-form-simple'}`}
    >
      {sections.map((section, index) =>
        renderSection({
          index,
          section,
          fieldsHooks,
          performSearch,
          isSearchOngoing,
          labels,
          setFieldVisiblity,
          toggleButtonsVisiblityHooks,
        })
      )}

      {doesAVisibleFieldIncludesSearchButton() !== true && (
        <CTAButton
          id="sfs-search-btn"
          label={labels.searchTaiga.search}
          action={performSearch}
          isEnabled={!isSearchOngoing}
          className="-round"
        />
      )}

      {!isAdvanced ? (
        <CTAButton
          id="sfs-go-to-advanced-btn"
          action={goToAdvanced}
          className="btn-as-text"
          label=""
        >
          <span>{labels.searchTaiga.goToAdvancedSearch}</span>
          <GenericIcon
            /* st0Class="chevron-right-icon-st0" src="icons-svg/control/chevron-droit-solid.svg" */ layout="i"
            className="chevron-right-icon"
          />
        </CTAButton>
      ) : (
        <CTAButton id="sfs-go-to-simple-btn" action={goToSimple} className="btn-as-text" label="">
          <GenericIcon layout="i" className="chevron-left-icon" />
          <span>{labels.searchTaiga.goToSimpleSearch}</span>
        </CTAButton>
      )}
    </div>
  );
}

SearchForm.propTypes = {
  isAdvanced: PropTypes.bool,
  toggleSearchMode: PropTypes.func.isRequired,
  search: PropTypes.func.isRequired,
  isSearchOngoing: PropTypes.bool.isRequired,
  hasResults: PropTypes.bool.isRequired,
  actions: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
};

export default SearchForm;

// Libs
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Conf
import { DATA_TYPE_SPEAKERS, getSpeakerSubtitle } from 'app-customs/config/dataConfig';

import { sortSpeakersByRankThenLastname } from 'app-customs/config/sortConfig';

import { elementPropsGetters } from 'app-customs/config/listConfig';

// App modules
import TypeBar from 'src/components/type-bar/TypeBar';
import EmailRow from 'src/components/fiche/EmailRow';
import DetailCountry from 'src/components/detail-country/DetailCountry';
import DetailSocial from 'src/components/detail-social/DetailSocial';

import './DetailSpeakers.scss';

function getListElementProps(speaker, propsGetter) {
  const props = {};
  Object.keys(propsGetter).forEach((propName) => {
    props[propName] = propsGetter[propName](speaker);
  });
  props.isClickable = false;
  return props;
}

/**
 * Common component to display related speakers
 * @param {array}  speakers
 * @param {object} labels
 * @param {object} store connected actions
 */
class DetailSpeakers extends PureComponent {
  render() {
    const length = Array.isArray(this.props.speakers)
      ? this.props.speakers.filter((item) => item).length
      : 0;

    if (length === 0) {
      // Skip component rendering
      return null;
    }

    let { label } = this.props;
    if (!label) {
      // Apply default label
      label = this.props.labels.data.speakers[length > 1 ? 'plural' : 'singular'];
    }

    const speakersPropsGetters = elementPropsGetters(DATA_TYPE_SPEAKERS);

    // Use TypeBar component to have foldable content
    const typeBars = [];
    sortSpeakersByRankThenLastname(this.props.speakers).forEach((speaker) => {
      const listElementProps = getListElementProps(speaker, speakersPropsGetters);
      const subtitle = getSpeakerSubtitle(speaker);
      const speakerSocial = speaker.lump?.social;
      let speakerHasContent = !!(
        speaker.biography ||
        (speakerSocial && (speakerSocial.tw || speakerSocial.fb || speakerSocial.ln))
      );

      speakerHasContent = speakerHasContent || (!!speaker.country && speaker.country !== subtitle);

      typeBars.push(
        <TypeBar
          key={speaker.id}
          label={listElementProps.text}
          subtitle={getSpeakerSubtitle(speaker)}
          image={listElementProps.image}
          isClickable={speakerHasContent}
          isClosed
          onToggle={this.props.onToggle}
        >
          {speakerHasContent ? (
            <div>
              {!!speaker.country && speaker.country !== subtitle && (
                <DetailCountry country={speaker.country} />
              )}
              <DetailSocial data={speaker.lump?.social} actions={this.props.actions} />
              {speaker.biography && <div dangerouslySetInnerHTML={{ __html: speaker.biography }} />}
              {speaker.lump && speaker.lump?.email && <EmailRow email={speaker.lump?.email} />}
            </div>
          ) : null}
        </TypeBar>
      );
    });

    return (
      <div className="detail-speakers-component">
        <div className="fiche-separator">{label}</div>
        <div className="fiche-related-data">{typeBars}</div>
      </div>
    );
  }
}

DetailSpeakers.propTypes = {
  speakers: PropTypes.array,
  labels: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  pageKey: PropTypes.string.isRequired,
  onToggle: PropTypes.func,
};

export default DetailSpeakers;

import { LIVE_STREAMING_PAGE_KEY, EVENT_PAGE_KEY } from 'src/pages/pagesKeys';

import GenericItemPage, { DOM_ID } from 'src/pages/generic-item-page/GenericItemPage';
import LiveStreamPage from './LiveStreamPage';

export default {
  key: LIVE_STREAMING_PAGE_KEY,
  path: '/streamLive',
  elId: DOM_ID,
  className: 'streamLive-page',
  component: GenericItemPage,
  childComponent: LiveStreamPage,
  relatedDataToFetch: ['events'],
};

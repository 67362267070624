import { CONTEST_PAGE_KEY } from 'src/pages/pagesKeys';

import GenericItemPage, { DOM_ID } from 'src/pages/generic-item-page/GenericItemPage';
import ContestContent from './ContestContent';

export default {
  key: CONTEST_PAGE_KEY,
  path: '/contest',
  elId: DOM_ID,
  className: 'contest-page',
  component: GenericItemPage,
  childComponent: ContestContent,
  relatedDataToFetch: [
    'exhibitor',
    'places',
    'speakers',
    'moderators',
    'contests',
    'papers',
    'gmapplaces',
  ],
};

import React from 'react';
import PropTypes from 'prop-types';

import config from 'app-customs/config/config';

import { isSessionValid } from 'src/core/login/LoginService';
import { horaireAutorized } from 'src/core/util/JsTools';

import VimeoPlayer from 'src/components/vimeo-player/VimeoPlayer';

import './liveStream.scss';
import { LOGIN_PAGE_KEY } from 'src/pages/pagesKeys';

function LiveStreamPage({ actions, pageTitle, queryString, labels, item }) {
  if (!(config.PLAYER_EMBED_VIMEOLIVE && config.PLAYER_EMBED_VIMEOLIVE.FEATURE_ENABLED)) {
    console.warn('FEATURES DISABLED: TRY TO ACTIVATE IT IN CONFIG DATA');
    return <></>;
  }
  if (!isSessionValid()) {
    actions.navigate(LOGIN_PAGE_KEY);
  }
  // return config.PLAYER_EMBED_VIMEOLIVE &&
  // config.PLAYER_EMBED_VIMEOLIVE.FEATURE_ENABLED && (
  //     !horaireAutorized(
  //         item.start_date,
  //         item.start_time,
  //         item.end_time,
  //         item.end_date
  //     ) &&
  //     isSessionValid() && (
  return (
    <div className="liveStream_container">
      <VimeoPlayer
        title={pageTitle}
        queryString={queryString}
        labels={labels}
        location={item.event_place_text}
        actions={actions}
      />
    </div>
  );
  //     )
  // )
}

LiveStreamPage.propTypes = {
  actions: PropTypes.object.isRequired,
  pageTitle: PropTypes.string,
  labels: PropTypes.object,
  location: PropTypes.string,
  queryString: PropTypes.string,
};

export default LiveStreamPage;

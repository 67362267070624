import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { debounce } from 'lodash-custom';
import Select from 'react-select';

import { GOOGLE_MAP_PAGE_KEY, GMAP_PLACES_PAGE_KEY, STORES_PAGE_KEY } from 'src/pages/pagesKeys';

import Menu from 'src/components/menu/Menu';
import AppToolbar from 'src/components/app-toolbar/AppToolbar';
import AdBanner from 'src/components/ad-banner/AdBanner';

import { getUrl } from 'src/core/data-and-assets/DataAssetsUtil';

import { fetchHappeningsDataLumpFromDB as happeningsData } from 'src/core/happenings/happenings';

import * as actions from 'src/store/actions';

import CTAButton from 'src/components/cta-button/CTAButton';

import config from 'app-customs/config/config';

import { CATEGORIES_MAPPING } from 'app-customs/config/dataConfig';

import 'app-customs/config-scss/GenericIcon.scss';

import { isAndroid, isIOS } from 'src/core/util/browser';

import { getBindedActions } from 'src/store/bindedActions';
import googleMapContext from './googleMapContext';

import * as GoogleMapMiddleware from './googleMapMiddleware';

import './googleMapPage.scss';

const LOG_PREF = '[googleMapPage] ';

const config_googlemap = config.MAP.GOOGLE_MAP;
const { HAS_LOCATION_BUTTON } = config_googlemap;

class googleMapPage extends Component {
  constructor(props) {
    super(props);

    this.pageKey = GOOGLE_MAP_PAGE_KEY;

    // @see ../../README.md#using-global-variables

    this.containerId = 'google-map-container';

    this.loadDataset = debounce(this._loadDataset.bind(this), 150);

    this.filterMap = this.filterMap.bind(this);
    this.initGoogleMap = this.initGoogleMap.bind(this);
    this.handleCategoriesFilter = this.handleCategoriesFilter.bind(this);
    this.handleLegendDialog = this.handleLegendDialog.bind(this);

    this.state = {
      data: [],
      initialData: null,
      map: null,
      gmapplaceCategorie: null,
      legendModalOpen: false,
      isLocationEnabled: props.isLocationEnabled || false,
      isLocationReady: props.isLocationReady || false,
      iconsLegend: [],
      mymarkers: [],
      myHtmlInfoWindow: null,
    };
  }

  UNSAFE_componentWillMount() {
    this.toggleLoaderAccordingToDataReadyStatus(this.props);
  }

  /**
   * Load google map dataset if data is ready when the component is mounted.
   * (e.g coming from another page)
   */
  componentDidMount() {
    this.loadDataset();
    this.setDocumentContext();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.poi && nextProps.poi !== this.props.poi && this.state.map) {
      const poiShow = this.props.gmapplaces.filter((place) => place.id == nextProps.poi.placeId);

      this.filterMap(poiShow[0], null, false);
    } else if (this.props.shouldReset !== nextProps.shouldReset && nextProps.shouldReset) {
      if (this.state.map) {
        this.filterMap(null, null, true);
        this.destroyGoogleMap();
      }
    } else {
      this.toggleLoaderAccordingToDataReadyStatus(nextProps);
    }
    if (nextProps.isLocationEnabled !== this.state.isLocationEnabled) {
      this.setState({ isLocationEnabled: nextProps.isLocationEnabled });
    }
    if (nextProps.isLocationReady !== this.state.isLocationReady) {
      this.setState({ isLocationReady: nextProps.isLocationReady });
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (this.props.gmapplaces !== nextProps.gmapplaces) {
      nextProps.gmapplaces.map((gmapplace) => {
        this.setState((prevState) => ({
          ...prevState,
          iconsLegend: [...prevState.iconsLegend, gmapplace.icon],
        }));
      });
      this.destroyGoogleMap();
    }
  }

  /**
   * Note: returning true does not result in google Map loading again from scratch.
   */
  shouldComponentUpdate(nextProps) {
    if (nextProps.isDataReady !== true) {
      return false;
    }

    // Window has been resize
    if (this.props.lastResize !== nextProps.lastResize) {
      if (googleMapContext.isNotLoaded()) {
        this.loadDataset();
      }

      if (this.props.orientation !== nextProps.orientation) {
        return true;
      }

      return false;
    }

    return true;
  }

  /**
   * Load google map dataset if data was not ready when the component has been mounted.
   * (e.g direct access to the page via url)
   */
  componentDidUpdate() {
    if (this.props.shouldRestart) {
      this.restart();
    }
    this.setDocumentContext();
  }

  /**
   * Update string visible in browser tab/history/favorites
   */
  setDocumentContext() {
    if (this.props.isActive(this.pageKey)) {
      this.props.setDocumentContext(this.getPageTitle());
    }
  }

  /**
   * String displayed in app toolbar
   * @return {string}
   */
  getPageTitle() {
    return this.props.labels.googleMap.title;
  }

  restart() {
    this.props.actions.googleMapIsRestarting();
    googleMapContext.setStatusRestarting();
    console.log(`${LOG_PREF}Map restart`);
    this.loadDataset();
  }

  _loadDataset() {
    if (this.props.isDataReady !== true) {
      console.log(`${LOG_PREF}Skipping map loading because data is not available yet`);
      return;
    }
    if (!googleMapContext.isLoading()) {
      console.info(`${LOG_PREF}Load map`);
      googleMapContext.setStatusLoading();
      this.props.actions.fetchGmapPlaces();
      GoogleMapMiddleware.addListeners();
      googleMapContext.setStatusLoaded();
    }
  }

  toggleLoaderAccordingToDataReadyStatus(props) {
    if (props.isDataReady !== true) {
      this.props.actions.showFullLoader();
    } else {
      this.props.actions.hideFullLoader();
    }
  }

  // Destroy a map completely.
  destroyGoogleMap() {
    this.state.map && this.state.map.remove();
    this.initGoogleMap();
  }

  initGoogleMap() {
    // Define a div tag with id="map_canvas"
    const mapDiv = document.getElementById('g-map');
    // Initialize the map plugin
    const map = plugin.google.maps.Map.getMap(mapDiv, {
      controls: {
        compass: true,
        myLocationButton: HAS_LOCATION_BUTTON,
        myLocation: HAS_LOCATION_BUTTON,
        zoom: true, // android only
        mapToolbar: false, // android only (itinerary button)
      },
    });
    this.setState({ map });

    // The MAP_READY event notifies the native map view is fully ready to use.
    map.one(plugin.google.maps.event.MAP_READY, onMapInit.bind(this));
    function onMapInit() {
      // Add markers
      const bounds = [];
      const markers = [];
      const myActions = this.props.actions;
      const htmlInfoWindow = new plugin.google.maps.HtmlInfoWindow();
      let mapHtmlInfoWindow;
      this.setState({ myHtmlInfoWindow: htmlInfoWindow }, () => {
        mapHtmlInfoWindow = this.state.myHtmlInfoWindow;
      });
      const { seeDetails } = this.props.labels.common;
      function onMarkerClick() {
        const marker = this;
        const myHtml = document.createElement('div');
        myHtml.innerHTML = `<div style='text-align: center; overflow-wrap: break-word; padding: 0em 1em 0.5em 0em;'><span class='close-btn close-modal-btn-google-map times-circle-icon'></span><h3>${marker.get(
          'gmapplaceTitle'
        )}</h3><h5>${marker.get(
          'address'
        )}</h5><button class='cta-btn'>${seeDetails}</button></div>`;
        const button = myHtml.getElementsByTagName('button')[0];
        const span = myHtml.getElementsByTagName('span')[0];
        const gmapplaceId = marker.get('gmapplaceId');
        const gmapstoreIds = marker.get('gmapstoreIds');
        button.addEventListener('click', () => {
          gmapstoreIds.length && config_googlemap.PAGE_KEY_RELATED_TO_USED_TABLE === STORES_PAGE_KEY
            ? myActions.navigate(config_googlemap.PAGE_KEY_RELATED_TO_USED_TABLE, {
                id: gmapstoreIds[0],
              })
            : myActions.navigate(GMAP_PLACES_PAGE_KEY, { id: gmapplaceId });
          mapHtmlInfoWindow.close();
        });
        span.addEventListener('click', () => {
          mapHtmlInfoWindow.close();
        });
        mapHtmlInfoWindow.setContent(myHtml, { width: '220px' });
        mapHtmlInfoWindow.open(marker);
      }
      this.props.gmapplaces.map((options) => {
        options.gmapplaceId = options.id;
        options.gmapstoreIds =
          options.lump && options.lump[CATEGORIES_MAPPING[config_googlemap.USED_TABLE]];
        options.gmapplaceTitle = options.title || options.gmapplaceTitle;
        delete options.title;
        options.icon = {
          url: getUrl(config_googlemap.PIN_PATH.PIN_MARKER_GOOLE_MAP_CLICKED),
          size: {
            width: 34,
            height: 34,
          },
        };
        const myMarker = map.addMarker(options, (marker) => {
          marker.on(plugin.google.maps.event.MARKER_CLICK, onMarkerClick);
        });
        bounds.push(options.position);
        markers.push(myMarker);
      });
      this.setState({ mymarkers: markers });
      this.setState({ mybounds: bounds });
      if (this.props.poi) {
        const poiShow = this.props.gmapplaces.filter((place) => place.id == this.props.poi.placeId);
        if (poiShow[0]) {
          // Set a camera position that includes all markers.
          map.moveCamera({
            target: [poiShow[0].position],
            zoom: 20,
          });
        } else {
          // Set a camera position that includes all markers.
          map.moveCamera({
            target: bounds,
          });
        }
      } else {
        // Set a camera position that includes all markers.
        map.moveCamera({
          target: bounds,
        });
      }
    }
    if (HAS_LOCATION_BUTTON) {
      let marker;
      const option = {
        enableHighAccuracy: false, // use GPS as much as possible
      };
      plugin.google.maps.LocationService.getMyLocation(
        option,
        () => {
          map.setMyLocationButtonEnabled(true);
          map.on(plugin.google.maps.event.MY_LOCATION_BUTTON_CLICK, () => {
            const onSuccess = function (location) {
              marker = map.addMarker({
                position: location.latLng,
              });

              map.animateCamera({
                target: location.latLng,
                zoom: 16,
              });
            };

            const onError = function (msg) {
              console.error(`${LOG_PREF} onError getMyLocation`, JSON.stringify(msg));
              getBindedActions().toggleLocationStatus(false);
            };
            marker && marker.remove();
            map.getMyLocation(onSuccess, onError);
          });
        },
        (error) => {
          console.error(`${LOG_PREF} onError LocationService getMyLocation`, error);
          getBindedActions().toggleLocationStatus(false);
        }
      );
    }
  }

  filterMap(place = null, categorie = null, clearFilter = false) {
    this.props.actions.showFullLoader();
    const myMap = this.state.map;
    this.state.myHtmlInfoWindow && this.state.myHtmlInfoWindow.close();
    if (clearFilter || place) {
      this.setState({ gmapplaceCategorie: null });
      this.state.mymarkers.map((marker) => {
        if (place && marker.get('id') === place.id) {
          marker.setIcon({
            url: getUrl(config_googlemap.PIN_PATH.PIN_MARKER_GOOLE_MAP),
            size: {
              width: 34,
              height: 34,
            },
          });
          marker.setVisible(true);
          myMap.moveCamera(
            {
              target: place.position,
              zoom: 20,
            },
            () => {
              marker.setAnimation(plugin.google.maps.Animation.BOUNCE);
            }
          );
        } else {
          marker.setIcon({
            url: getUrl(config_googlemap.PIN_PATH.PIN_MARKER_GOOLE_MAP_CLICKED),
            size: {
              width: 34,
              height: 34,
            },
          });
          marker.setVisible(true);
        }
      });
      if (clearFilter) {
        myMap.moveCamera({
          target: this.state.mybounds,
        });
      }
    }
    if (categorie) {
      const filteredPlaces =
        this.props.gmapplaces &&
        this.props.gmapplaces.filter(
          (place) => place.lump?.cats && place.lump?.cats.includes(parseInt(categorie))
        );
      const filteredPlacesIds = filteredPlaces.map((place) => place.id);

      this.state.mymarkers.map((marker) => {
        if (filteredPlacesIds.indexOf(marker.get('id')) !== -1) {
          marker.setIcon({
            url: getUrl(config_googlemap.PIN_PATH.PIN_MARKER_GOOLE_MAP_CLICKED),
            size: {
              width: 34,
              height: 34,
            },
          });
          marker.setVisible(true);
        } else {
          marker.setVisible(false);
        }
      });

      const catBound = [];
      filteredPlaces.map((options) => {
        catBound.push(options.position);
      });
      catBound &&
        myMap.animateCamera({
          target: catBound,
          zoom: 15,
        });
    }
    this.props.actions.hideFullLoader();
  }

  handleCategoriesFilter(event) {
    this.setState({ gmapplaceCategorie: event }, () => {
      // event.id
      this.filterMap(null, this.state.gmapplaceCategorie.id, false);
    });
  }

  handleLegendDialog(value) {
    this.setState({ legendModalOpen: value });
  }

  render() {
    const filerByCategories =
      config.MAP.GOOGLE_MAP.HAS_GMAP_FILTER_BY_CATEGORIES &&
      this.props.gmapplacesCategories &&
      this.props.gmapplacesCategories.length !== 0;
    const hasSeeAllBtn = config.MAP.GOOGLE_MAP.HAS_SEE_ALL_BTN;
    const hasFeatureButtons = filerByCategories || hasSeeAllBtn;

    console.log(
      `${LOG_PREF}render isLocationEnabled:`,
      this.state.isLocationEnabled,
      'isLocationReady',
      this.state.isLocationReady
    );

    // Always keep that page's DOM
    // if (!this.props.isVisible) return null;

    if (this.props.isDataReady !== true) {
      return null;
    }
    return (
      <>
        <Menu
          actions={this.props.actions}
          labels={this.props.labels}
          profile={this.props.profile}
          options={this.props.menuOptions}
          associatedPageKey={this.pageKey}
          adConfig={this.props.adConfig}
          twoColumns={this.props.twocolumns}
          isLocationEnabled={this.props.isLocationEnabled}
        />

        <AppToolbar
          labels={this.props.labels}
          isDisplayed={this.props.hasToolbar}
          actions={this.props.actions}
          title={this.getPageTitle()}
          pageKey={this.pageKey}
          profile={this.props.profile}
          hasBackButton={this.props.backButtonInToolbar}
          listToolBarButton={this.props.listToolBarButton}
          listConfigToolBarButton={this.props.listConfigToolBarButton}
          hasHomeButton={this.props.homeButtonInToolbar}
          hasFavButton={this.props.favButtonInToolbar}
          hasSearchButton={this.props.searchButtonInToolbar}
          hasMenuButton={this.props.menuButtonInToolbar}
        />

        {happeningsData() && (
          <AdBanner
            ad={happeningsData()}
            adFiles={happeningsData().files}
            currentLang={this.props.labels.id}
            actions={this.props.actions}
          />
        )}

        <div
          id="google-map-container-wrapper"
          className="content-below-apptoolbar"
          style={{ height: happeningsData() ? 'calc(100% - 130px)' : 'calc(100% - 48px)' }}
        >
          <div>
            <div id={this.containerId}>
              <div id="g-map" style={{ height: hasFeatureButtons ? 'calc(100vh - 24vh)' : '94vh' }}>
                {HAS_LOCATION_BUTTON &&
                  ((this.state.isLocationEnabled === false && isIOS()) ||
                    (isAndroid() &&
                      (this.state.isLocationEnabled === false ||
                        this.state.isLocationReady === false))) && (
                    <img
                      style={
                        isAndroid()
                          ? {
                              position: 'absolute',
                              top: 5,
                              right: 5,
                              backgroundColor: 'white',
                              borderRadius: 4,
                            }
                          : {
                              position: 'absolute',
                              bottom: 7,
                              right: 7,
                              padding: 2,
                              backgroundColor: 'white',
                              borderRadius: '50%',
                            }
                      }
                      onClick={() => {
                        window.navigator.geolocation.goSettings('app', {
                          labels: {
                            questionTitle: this.props.labels.mobigeo.goSettingsQuestionTitle,
                            question: this.props.labels.mobigeo.goSettingsQuestion,
                            yes: this.props.labels.common.yes,
                            no: this.props.labels.common.no,
                          },
                        });
                      }}
                      src={getUrl(config.MAP.GOOGLE_MAP.DEFAULT_LOCATION_IMAGE, false)}
                      alt={this.props.labels.mobigeo.locationSettingWarning}
                    />
                  )}
              </div>
            </div>

            {hasFeatureButtons && (
              <div className="gmap-buttons">
                {filerByCategories && (
                  <Select
                    placeholder={this.props.labels.common.filterByCategory}
                    options={this.props.gmapplacesCategories}
                    getOptionLabel={(option) => option.title}
                    getOptionValue={(option) => option.id}
                    onChange={this.handleCategoriesFilter}
                    menuPlacement="top"
                    isSearchable={false}
                  />
                )}
                {hasSeeAllBtn && (
                  <CTAButton
                    action={() => {
                      this.filterMap(null, null, true);
                    }}
                    label={this.props.labels.common.seeAll}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

googleMapPage.propTypes = {
  gmapplaces: PropTypes.array,
  gmapplacesCategories: PropTypes.array,
  isPending: PropTypes.bool,
  lastResize: PropTypes.number,
  isDataReady: PropTypes.bool,
  shouldRestart: PropTypes.bool,
  shouldReset: PropTypes.bool,
  // Common page props
  menuOptions: PropTypes.object.isRequired,
  profile: PropTypes.string,
  labels: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  isActive: PropTypes.func.isRequired,
  setDocumentContext: PropTypes.func.isRequired,
  isLocationEnabled: PropTypes.bool,
  isLocationReady: PropTypes.bool,
  isVisible: PropTypes.bool, // set by togglePageAfterNavigation common reducer function
  isAppVisible: PropTypes.bool,
  // toolbar
  hasToolbar: PropTypes.bool,
  homeButtonInToolbar: PropTypes.bool,
  backButtonInToolbar: PropTypes.bool,
  searchButtonInToolbar: PropTypes.bool,
  favButtonInToolbar: PropTypes.bool,
  menuButtonInToolbar: PropTypes.bool,
  routingDestinationPoi: PropTypes.object,
};

const mapStateToProps = (state, ownProps) => state[GOOGLE_MAP_PAGE_KEY];
const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(mapStateToProps, mapDispatchToProps)(googleMapPage);

import React from 'react';
import PropTypes from 'prop-types';

import GenericIcon from 'src/components/generic-icon/GenericIcon';

function FullNameRow(props) {
  return props.lastname || props.firstname || props.fullName ? (
    <div className="free-row">
      <div className="prop-img">
        <div className="prop-left">
          <GenericIcon
            style={{
              height: '2em',
              width: '2em',
            }}
            className="user-circle-icon icon-color2"
          />
        </div>
        <div
          className="prop-right"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          {props.fullName ? (
            <a className="link colored-link">{props.fullName}</a>
          ) : (
            <a className="link colored-link">
              {props.civility ? props.civility : ''} {props.firstname ? props.firstname : ''}{' '}
              {props.lastname ? props.lastname : ''}
            </a>
          )}
        </div>
      </div>
    </div>
  ) : null;
}

FullNameRow.propTypes = {
  lastname: PropTypes.string,
  firstname: PropTypes.string,
  civility: PropTypes.string,
  fullName: PropTypes.string,
};

export default FullNameRow;

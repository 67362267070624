import { JOBOFFERS_PAGE_KEY } from 'src/pages/pagesKeys';

import GenericItemPage, { DOM_ID } from 'src/pages/generic-item-page/GenericItemPage';
import JobOfferContent from './JobOfferContent';

export default {
  key: JOBOFFERS_PAGE_KEY,
  path: '/joboffers',
  elId: DOM_ID,
  className: 'job-offer-page',
  component: GenericItemPage,
  childComponent: JobOfferContent,
  relatedDataToFetch: ['exhibitor'],
};

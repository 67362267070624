// Libs
import React from 'react';
import PropTypes from 'prop-types';

// App modules
import Url from 'src/components/url/Url';
import { getUrl } from 'src/core/data-and-assets/DataAssetsUtil';

import './AdSimple.css';

const LOG_PREF = '[AdSimple] ';

function AdSimple({ ad, actions }) {
  if (!ad) {
    return null;
  }

  console.log(`${LOG_PREF}render`, ad);

  const style = {};
  if (ad.bgcolor) {
    style.backgroundColor = ad.bgcolor;
  }

  return (
    <div className="ad-simple-container">
      <Url
        className="ad-simple-link"
        href={ad.link}
        target={ad.target}
        openInInAppBrowser={ad.openInInAppBrowser}
        callback={actions.linkClicked}
        style={style}
      >
        <img src={getUrl(ad.img)} role="presentation" className="ad-simple-img" />
      </Url>
    </div>
  );
}

AdSimple.propTypes = {
  ad: PropTypes.object,
};

export default AdSimple;

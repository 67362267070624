import React from 'react';
import PropTypes from 'prop-types';

import GenericIcon from 'src/components/generic-icon/GenericIcon';
import FooterGenericButton from './FooterGenericButton';

function FooterBackButton({ labels, callback }) {
  return (
    <FooterGenericButton className="klf-back-btn" callback={callback}>
      <GenericIcon layout="div" className="klf-back-btn-icon arrow-left-icon" />
      <div className="klf-back-btn-label content-font">{labels.klipsoLeads.back}</div>
    </FooterGenericButton>
  );
}

FooterBackButton.propTypes = {
  labels: PropTypes.object.isRequired,
  callback: PropTypes.func,
};

export default FooterBackButton;
